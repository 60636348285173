import { USER_DETAILS_FAIL, USER_DETAILS_REQUEST } from "../user/actionType";
import { userDetails } from "../user/actions";
import axios from "./utils/axios";

const fetchUserDetailsReducer = (email) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: USER_DETAILS_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(`api/auth/user/${email}/`, config);
      dispatch(userDetails(data));
    } catch (error) {
      dispatch({
        type: USER_DETAILS_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };
};
export default fetchUserDetailsReducer;
