import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fetchUserDetailsReducer from "../redux/thunk/fetchUserDetailsReducer";
import fetchDepartmentListViewReducer from "../redux/thunk/fetchDepartmentListViewReducer"; // Import your department fetch action
import fetchUserUpdateReducer from "../redux/thunk/fetchUserUpdateReducer";

export default function EditUser({ sendAppCloseAction, user_email }) {
  const dispatch = useDispatch();

  // Fetch user details and department list from the Redux store
  const userDetails = useSelector((state) => state.userDetails);
  const departmentList = useSelector((state) => state.departmentList);
  const { user } = userDetails || {};
  const { departments } = departmentList || {};

  // Initialize state variables
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [department, setDepartment] = useState("");

  // Update state variables when user details are fetched
  useEffect(() => {
    if (user) {
      setFirstName(user.first_name || "");
      setLastName(user.last_name || "");
      setEmail(user.email || "");
      setDepartment(user.department || "");
    }
  }, [user]);

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      fetchUserUpdateReducer(firstName, lastName, email, department, user_email)
    );
    sendAppCloseAction(false);
  };
  // Fetch user details and department list on component mount
  useEffect(() => {
    dispatch(fetchUserDetailsReducer(user_email));
    dispatch(fetchDepartmentListViewReducer);
  }, [dispatch, user_email]);
  // Close the popup
  const handleClick = () => {
    sendAppCloseAction(false);
  };

  // Close the popup on cancel
  const cancelHandler = () => {
    sendAppCloseAction(false);
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <span className="close" onClick={handleClick}>
          &times;
        </span>
        <h1 style={{ padding: "10px 40px" }}>Update User Section</h1>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <form
            style={{
              width: "60%",
              position: "relative",
            }}
            onSubmit={handleSubmit}
          >
            <div
              style={{
                margin: "30px 0px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label style={{ fontSize: "20px" }}>Enter First Name</label>
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                style={{ padding: "10px 20px", width: "220px" }}
              />
            </div>
            <div
              style={{
                margin: "30px 0px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label style={{ fontSize: "20px" }}>Enter Last Name</label>
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                style={{ padding: "10px 20px", width: "220px" }}
              />
            </div>
            <div
              style={{
                margin: "30px 0px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label style={{ fontSize: "20px" }}>Enter Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ padding: "10px 20px", width: "220px" }}
              />
            </div>
            <div
              style={{
                margin: "30px 0px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label style={{ fontSize: "20px" }}>Department</label>
              <select
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
                style={{
                  padding: "10px 20px",
                  width: "262px",
                  fontSize: "16px",
                  textTransform: "capitalize",
                }}
              >
                <option value={null}>Select Department</option>
                {departments?.map((dept) => (
                  <option key={dept.id} value={dept.department_name}>
                    {dept.department_name}
                  </option>
                ))}
              </select>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                margin: "40px 0px",
              }}
            >
              <button
                type="submit"
                style={{
                  width: "140px",
                  height: "35px",
                  borderRadius: "10px",
                  borderStyle: "none",
                  background: "#486776",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                Update User
              </button>
              <button
                type="button"
                style={{
                  width: "140px",
                  height: "35px",
                  borderRadius: "10px",
                  borderStyle: "none",
                  background: "red",
                  color: "white",
                  cursor: "pointer",
                }}
                onClick={cancelHandler}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
