import {
  ANNOUNCEMENT_UPDATE_FAIL,
  ANNOUNCEMENT_UPDATE_REQUEST,
} from "../announcement/actionType";
import { updateAnnouncement } from "../announcement/actions";
import axios from "./utils/axios";

const fetchAnnouncementUpdateReducer = (
  title,
  details,
  departmentName,
  announcement_id
) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ANNOUNCEMENT_UPDATE_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `api/announcement/update/${announcement_id}/`,
        { title, details, department_name: departmentName },
        config
      );
      dispatch(updateAnnouncement(data));
    } catch (error) {
      dispatch({
        type: ANNOUNCEMENT_UPDATE_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };
};
export default fetchAnnouncementUpdateReducer;
