import {
  PROFILE_DETAILS_FAIL,
  PROFILE_DETAILS_REQUEST,
  PROFILE_DETAILS_SUCCESS,
  PROFILE_VIEW_FAIL,
  PROFILE_VIEW_REQUEST,
  PROFILE_VIEW_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  USER_CREATE_FAIL,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_FORGET_PASSWORD_FAIL,
  USER_FORGET_PASSWORD_REQUEST,
  USER_FORGET_PASSWORD_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_PASSWORD_VERIFY_FAIL,
  USER_PASSWORD_VERIFY_REQUEST,
  USER_PASSWORD_VERIFY_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
} from "./actionType";

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        loading: true,
      };
    case USER_LOGIN_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
        isError: false,
      };

    case USER_LOGIN_FAIL:
      return {
        loading: false,
        userInfo: "",
        isError: true,
        error: action.payload,
      };

    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
};

export const profileViewReducer = (state = {}, action) => {
  switch (action.type) {
    case PROFILE_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PROFILE_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload,
        isError: false,
      };

    case PROFILE_VIEW_FAIL:
      return {
        ...state,
        loading: false,
        profile: "",
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const profileUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload,
        isError: false,
        success: true,
      };

    case UPDATE_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        profile: "",
        isError: true,
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const profileDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case PROFILE_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload,
        isError: false,
      };

    case PROFILE_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        profile: "",
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const userDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        isError: false,
      };

    case USER_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        user: "",
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};
export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        isError: false,
      };

    case USER_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        user: "",
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const userCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CREATE_REQUEST:
      return {
        ...state,
        success: false,
        loading: true,
      };
    case USER_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        user: action.payload,
        isError: false,
      };

    case USER_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        user: "",
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const userListReducer = (state = [], action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
        isError: false,
      };

    case USER_LIST_FAIL:
      return {
        ...state,
        loading: false,
        users: "",
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const userUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        isError: false,
      };

    case USER_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        user: "",
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const forgetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_FORGET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case USER_FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        user: action.payload,
        isError: false,
      };

    case USER_FORGET_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        user: "",
        isError: true,
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const passwordVerifyReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PASSWORD_VERIFY_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case USER_PASSWORD_VERIFY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        user: action.payload,
        isError: false,
      };

    case USER_PASSWORD_VERIFY_FAIL:
      return {
        ...state,
        loading: false,
        user: "",
        success: false,
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};
