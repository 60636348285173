import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import avatar from "../../assets/avatar.png";
import { useDispatch, useSelector } from "react-redux";
import fetchProfileAppListReducer from "../../redux/thunk/fetchProfileAppList";
import AssignApp from "../AssignApp";
import AssignFolder from "../AssignFolder";
import fetchProfileDetailsReducer from "../../redux/thunk/fetchProfileDetailsReducer";
import SelectedApp from "../SelectedApp";
import fetchUnassignIconReducer from "../../redux/thunk/fetchUnassignIconReducer";
import SingleApp from "../SingleApp";

export default function ProfileDetails() {
  const { email } = useParams();
  const [name, setName] = useState("");
  const [department, setDepartment] = useState("");
  const [removeShow, setRemoveShow] = useState(false);
  const [profilePic, setProfilePic] = useState("");
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [refreshApp, setRefreshApp] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState([]);
  const dispatch = useDispatch();
  const [assignAppOpen, setAssignAppOpen] = useState(false);
  const [assignFolderOpen, setAssignFolderOpen] = useState(false);
  let navigate = useNavigate();
  const login = useSelector((state) => state.login);
  const profileAppList = useSelector((state) => state.profileAppList);
  const profileDetails = useSelector((state) => state.profileDetails);
  const { userInfo } = login || {};
  const { profile } = profileDetails || {};
  const { folders } = profileAppList || {};
  console.log(folders);

  const closeDetails = (newMessage) => {
    setAssignAppOpen(newMessage);
    setAssignFolderOpen(newMessage);
    setRefreshApp(true);
  };

  const handleSelectChange = (id) => {
    setSelectedIcon((prevSelected) => {
      const newSelected = prevSelected.includes(id)
        ? prevSelected.filter((appId) => appId !== id)
        : [...prevSelected, id];
      return newSelected;
    });
  };
  const removeShowHandler = () => {
    setRemoveShow((prevState) => !prevState);
    setShowDeleteButton(false);
    setSelectedIcon([]);
  };

  const iconDeleteHandler = (e) => {
    e.preventDefault();
    dispatch(fetchUnassignIconReducer(email, selectedIcon));
    setSelectedIcon([]);
    setRefreshApp(true);
  };

  useEffect(() => {
    dispatch(fetchProfileAppListReducer(email));
    dispatch(fetchProfileDetailsReducer(email));
    if (!userInfo) {
      navigate("/");
    }
    setRefreshApp(false);
  }, [navigate, userInfo, dispatch, email, refreshApp]);

  useEffect(() => {
    if (profile) {
      setName(profile?.name);
      setDepartment(profile?.department);
      setProfilePic(profile?.profile_pic);
    }
  }, [profile]);

  useEffect(() => {
    if (selectedIcon.length > 0) {
      setShowDeleteButton(true);
    } else {
      setShowDeleteButton(false);
    }
  }, [selectedIcon]);

  return (
    <>
      {userInfo?.isAdmin ? (
        <div>
          <div style={{ padding: "20px 30px" }}>
            <h2 style={{ marginLeft: "40px" }}>Profile Details</h2>
            <br />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{
                  border: "1.5px dotted black",
                  width: "60%",
                  padding: "10px 30px",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  margin: "auto 0px",
                  position: "relative",
                  borderRadius: "12px",
                }}
              >
                <img
                  src={profilePic || avatar}
                  alt="profile"
                  style={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "50%",
                  }}
                />
                <div className="oswald">
                  <p style={{ textTransform: "capitalize" }}>
                    Name : {name || "Unknown User"}
                  </p>
                  <p style={{ textTransform: "capitalize" }}>
                    Department : {department || "No Department"}
                  </p>
                  <p>Email : {email}</p>
                </div>
              </div>
            </div>
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px 50px",
              }}
            >
              <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                {" "}
                All Assigned Apps
              </p>
              <div
                style={{ display: "flex", alignItems: "center", gap: "25px" }}
              >
                {userInfo?.isAdmin && !showDeleteButton && (
                  <div
                    className="app-create"
                    onClick={() => setAssignAppOpen(true)}
                  >
                    Assign Apps
                  </div>
                )}
                {userInfo?.isAdmin && !showDeleteButton && (
                  <div
                    className="folder-create"
                    onClick={() => setAssignFolderOpen(true)}
                  >
                    Assign Folders
                  </div>
                )}
                {userInfo?.isAdmin && showDeleteButton && (
                  <div className="icon-delete" onClick={iconDeleteHandler}>
                    Remove Icons
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                padding: "10px 50px",
                cursor: "pointer",
                fontStyle: "italic",
                color: "red",
              }}
              onClick={removeShowHandler}
            >
              {removeShow
                ? "Cancel Remove Operation "
                : "Click For Remove Folder/App"}
            </div>
            <div className="app-container">
              {folders?.map((folder, index) =>
                removeShow ? (
                  <SelectedApp
                    key={index}
                    id={folder.id}
                    title={folder.title}
                    image={folder.image}
                    color={folder?.color}
                    url={folder.url}
                    isFolder={folder.isFolder}
                    selected={selectedIcon.includes(folder?.id)} // Make sure it is string comparison
                    handleSelectChange={handleSelectChange}
                  />
                ) : (
                  <SingleApp
                    key={index}
                    id={folder.id}
                    title={folder.title}
                    image={folder.image}
                    url={folder.url}
                    isFolder={folder.isFolder}
                    deleteShow={false}
                    refreshOnDelete={null}
                  />
                )
              )}
              {/* id,
  title,
  image,
  url,
  isFolder,
  deleteShow,
  refreshOnDelete, */}
            </div>
          </div>
          {assignAppOpen && (
            <AssignApp sendAppCloseAction={closeDetails} email={email} />
          )}
          {assignFolderOpen && (
            <AssignFolder sendFolderCloseAction={closeDetails} email={email} />
          )}
        </div>
      ) : (
        <p style={{ textAlign: "center", fontSize: "30px", color: "red" }}>
          No Content Availalbe{" "}
        </p>
      )}
    </>
  );
}
