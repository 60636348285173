import React, { useEffect, useState } from "react";
import folder from "../assets/test.png";
import SelectedApp from "./SelectedApp";
import fetchProfileUnassignFolderListReducer from "../redux/thunk/fetchProfileUnassignFolderListReducer";
import { useDispatch, useSelector } from "react-redux";
import fetchFolderAssignReducer from "../redux/thunk/fetchFolderAssignReducer";

export default function AssignFolder({ sendFolderCloseAction, email }) {
  const [selectedFolders, setSelectedFolders] = useState([]);
  const dispatch = useDispatch();
  const profileUnassignFolders = useSelector(
    (state) => state.profileUnassignFolders
  );
  const { folders } = profileUnassignFolders || [];
  console.log(folders);

  const handleSelectChange = (id) => {
    setSelectedFolders((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((appId) => appId !== id)
        : [...prevSelected, id]
    );
  };

  const handleAssignClick = () => {
    console.log(selectedFolders);
    dispatch(fetchFolderAssignReducer(email, selectedFolders));
    sendFolderCloseAction(false);
    // Add your assignment logic here
  };

  const handleClick = () => {
    sendFolderCloseAction(false);
  };
  useEffect(() => {
    dispatch(fetchProfileUnassignFolderListReducer(email));
  }, [dispatch]);
  return (
    <div className="popup">
      <div className="popup-content">
        <span className="close" onClick={handleClick}>
          &times;
        </span>
        <h1 style={{ padding: "10px 40px" }}>Assign Folder Section </h1>
        <div className="app-container-box">
          {folders?.map((folder) => (
            <SelectedApp
              key={folder?.id}
              isFolder={true}
              title={folder?.folder_title}
              image={folder?.folder_image}
              color={folder?.color}
              url={null}
              id={folder?.id}
              selected={selectedFolders.includes(folder?.id)} // Make sure it is string comparison
              handleSelectChange={handleSelectChange}
            />
          ))}
        </div>
        <button
          onClick={handleAssignClick}
          className="folder-create"
          style={{ margin: "40px", borderStyle: "none", width: "150px" }}
        >
          Assign
        </button>
      </div>
    </div>
  );
}
