import {
  DEPARTMENT_LIST_VIEW_FAIL,
  DEPARTMENT_LIST_VIEW_REQUEST,
} from "../department/actionType";
import { departmentListView } from "../department/actions";
import axios from "./utils/axios";

const fetchDepartmentListViewReducer = async (dispatch, getState) => {
  try {
    dispatch({ type: DEPARTMENT_LIST_VIEW_REQUEST });
    const {
      login: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`api/department/get-all/`, config);
    dispatch(departmentListView(data));
  } catch (error) {
    dispatch({
      type: DEPARTMENT_LIST_VIEW_FAIL,
      payload:
        error.response && error.response.data.details
          ? error.response.data.details
          : error.message,
    });
  }
};
export default fetchDepartmentListViewReducer;
