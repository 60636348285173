import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fetchDepartmentListViewReducer from "../../redux/thunk/fetchDepartmentListViewReducer";
import edit from "../../assets/edit.png";
import deleteImg from "../../assets/delete.png";
import fetchDepartmentCreateReducer from "../../redux/thunk/fetchCreateDepartmentReducer";
import fetchDepartmentUpdateReducer from "../../redux/thunk/fetchUpdateDepartmentReducer";
import fetchDepartmentDeleteReducer from "../../redux/thunk/fetchDeleteDepartmentReducer";
import { useNavigate } from "react-router-dom";
import Loading from "../ui/Loading";
import Error from "../ui/Error";
import Success from "../ui/Success";
export default function Departments() {
  const [departmentName, setDepartmentName] = useState("");
  const [id, setId] = useState(0);
  const [shouldReload, setShouldReload] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();
  const departmentList = useSelector((state) => state.departmentList);
  const { departments, loading, isError, error } = departmentList || {};
  let navigate = useNavigate();
  const login = useSelector((state) => state.login);
  const departmentCreate = useSelector((state) => state.departmentCreate);
  const departmentUpdate = useSelector((state) => state.departmentUpdate);
  const departmentDelete = useSelector((state) => state.departmentDelete);
  const { userInfo } = login || {};
  const {
    loading: createLoading,
    success,
    isError: createIsError,
    error: createError,
  } = departmentCreate || {};
  const {
    loading: updateLoading,
    success: updateSuccess,
    isError: updateIsError,
    error: updateError,
  } = departmentUpdate || {};
  const {
    loading: deleteLoading,
    success: deleteSuccess,
    isError: deleteIsError,
    error: deleteError,
  } = departmentDelete || {};
  const deleteDepartmentHandler = (department_name) => {
    dispatch(fetchDepartmentDeleteReducer(department_name)).then(() => {
      setShouldReload((prev) => !prev); // Toggle the shouldReload state
    });
  };
  const addDepartmentHandler = (e) => {
    e.preventDefault();
    dispatch(fetchDepartmentCreateReducer(departmentName)).then(() => {
      setDepartmentName("");
      setShouldReload((prev) => !prev); // Toggle the shouldReload state
    });
  };
  const updateDepartmentHandler = (e) => {
    e.preventDefault();
    dispatch(fetchDepartmentUpdateReducer(id, departmentName)).then(() => {
      setShouldReload((prev) => !prev); // Toggle the shouldReload state
    });
  };

  const editDepartmentHandler = (department_name, id) => {
    setIsEdit(true);
    setId(id);
    setDepartmentName(department_name);
  };
  const cancelEditHandler = () => {
    setIsEdit(false);
    setDepartmentName("");
  };

  useEffect(() => {
    dispatch(fetchDepartmentListViewReducer);
    if (!userInfo) {
      navigate("/");
    }
  }, [dispatch, shouldReload, userInfo, navigate]);

  return (
    <div style={{ width: "100%", height: "auto", padding: "20px" }}>
      <h1>Department Section</h1>
      <br />
      <br />
      {(loading || createLoading || updateLoading || deleteLoading) && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loading />
        </div>
      )}
      {(success || updateSuccess || deleteSuccess) && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <Success
            message={`Department ${
              success
                ? "Created"
                : updateSuccess
                ? "Updated"
                : deleteSuccess
                ? "Delete"
                : ""
            } Successfully`}
          />
        </div>
      )}
      {(updateIsError || createIsError || deleteIsError) && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <Error
            message={
              createIsError
                ? createError
                : updateIsError
                ? updateError
                : deleteIsError
                ? deleteError
                : ""
            }
          />
        </div>
      )}
      <form
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "50px",
        }}
        onSubmit={isEdit ? updateDepartmentHandler : addDepartmentHandler}
      >
        <label style={{ fontSize: "20px" }}>Department Name:</label>
        <input
          type="text"
          value={departmentName}
          onChange={(e) => setDepartmentName(e.target.value)}
          style={{
            width: "300px",
            height: "40px",
            fontSize: "18px",
            padding: "0px 20px",
            borderRadius: "12px",
            textTransform: "capitalize",
          }}
          placeholder="Enter Department Name"
        />
        <button
          className="app-create"
          style={{ borderStyle: "none" }}
          type="submit"
        >
          {isEdit ? "Update" : "Add"} Department
        </button>
        {isEdit && (
          <button
            className="cancel-button"
            style={{ borderStyle: "none" }}
            onClick={cancelEditHandler}
          >
            Cancel
          </button>
        )}
      </form>
      <br />
      <br />
      {isError && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <Error message={error} />
        </div>
      )}
      {!isError && (
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr style={{ textAlign: "center" }}>
              <th style={{ border: "1px solid #ddd", padding: "8px" }}>ID</th>

              <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                Department Name
              </th>
              <th style={{ border: "1px solid #ddd", padding: "8px" }}>#</th>
            </tr>
          </thead>
          {departments?.length > 0 ? (
            <tbody>
              {Array.isArray(departments) &&
                departments?.map((department, index) => (
                  <tr style={{ textAlign: "center" }} key={index}>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      {index + 1}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textTransform: "capitalize",
                      }}
                    >
                      {department.department_name}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          gap: "20px",
                        }}
                      >
                        <img
                          style={{
                            width: "20px",
                            height: "20px",
                            cursor: "pointer",
                            overflow: "hidden",
                          }}
                          onClick={() =>
                            editDepartmentHandler(
                              department.department_name,
                              department.id
                            )
                          }
                          src={edit}
                          alt="edit"
                        />
                        <img
                          style={{
                            width: "20px",
                            height: "20px",
                            cursor: "pointer",
                            overflow: "hidden",
                          }}
                          src={deleteImg}
                          alt="delete"
                          onClick={() =>
                            deleteDepartmentHandler(department.department_name)
                          }
                        />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              No Data Found
            </div>
          )}
        </table>
      )}
    </div>
  );
}
