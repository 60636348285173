import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fetchDepartmentListViewReducer from "../redux/thunk/fetchDepartmentListViewReducer";
import fetchAnnouncementDetailsViewReducer from "../redux/thunk/fetchAnnouncementDetailsViewReducer";
import fetchAnnouncementUpdateReducer from "../redux/thunk/fetchAnnouncementUpdateReducer";

export default function EditNotice({ editNoticeCloseAction, announcement_id }) {
  const dispatch = useDispatch();

  const departmentList = useSelector((state) => state.departmentList);
  const announcementViewDetails = useSelector(
    (state) => state.announcementViewDetails
  );

  const { departments } = departmentList || {};
  const { announcement } = announcementViewDetails || {};

  const [title, setTitle] = useState("");
  const [details, setDetails] = useState("");
  const [departmentName, setDepartmentName] = useState("");

  useEffect(() => {
    // Fetch announcement details and department list when component mounts
    dispatch(fetchAnnouncementDetailsViewReducer(announcement_id));
    dispatch(fetchDepartmentListViewReducer);
  }, [dispatch, announcement_id]);

  useEffect(() => {
    // Update state values when announcement data is fetched
    if (announcement) {
      setTitle(announcement.title);
      setDetails(announcement.details);
      setDepartmentName(announcement.department);
    }
  }, [announcement]);

  const editSubmit = (e) => {
    e.preventDefault();
    dispatch(
      fetchAnnouncementUpdateReducer(
        title,
        details,
        departmentName,
        announcement_id
      )
    );
    editNoticeCloseAction(false);
  };

  const handleClick = () => {
    editNoticeCloseAction(false);
  };

  const cancelHandler = () => {
    editNoticeCloseAction(false);
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <span className="close" onClick={handleClick}>
          &times;
        </span>
        <h1 style={{ padding: "10px 40px" }}>Update Notice Section</h1>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <form
            style={{
              width: "60%",
              position: "relative",
            }}
            onSubmit={editSubmit}
          >
            <div
              style={{
                margin: "30px 0px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label style={{ fontSize: "20px" }}>Enter Title</label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                style={{ padding: "10px 20px", width: "220px" }}
              />
            </div>
            <div
              style={{
                margin: "30px 0px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label style={{ fontSize: "20px" }}>Enter Details</label>
              <textarea
                value={details}
                onChange={(e) => setDetails(e.target.value)}
                style={{
                  padding: "10px 20px",
                  width: "220px",
                  height: "100px",
                }}
              />
            </div>
            <div
              style={{
                margin: "30px 0px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label style={{ fontSize: "20px" }}>Department</label>
              <select
                value={departmentName}
                onChange={(e) => setDepartmentName(e.target.value)}
                style={{
                  padding: "10px 20px",
                  width: "262px",
                  fontSize: "16px",
                  textTransform: "uppercase",
                }}
              >
                <option value="" disabled>
                  Select Department
                </option>
                {departments?.map((department, index) => (
                  <option
                    key={index}
                    value={department?.department_name}
                    style={{ textTransform: "uppercase" }}
                  >
                    {department?.department_name}
                  </option>
                ))}
              </select>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                margin: "40px 0px",
              }}
            >
              <button
                type="submit"
                style={{
                  width: "150px",
                  height: "40px",
                  borderRadius: "10px",
                  borderStyle: "none",
                  background: "#486776",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                Update Annoucement
              </button>
              <button
                type="button" // Add type="button" to prevent it from submitting the form
                style={{
                  width: "150px",
                  height: "40px",
                  borderRadius: "10px",
                  borderStyle: "none",
                  background: "red",
                  color: "white",
                  cursor: "pointer",
                }}
                onClick={cancelHandler}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
