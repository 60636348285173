import {
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_REQUEST,
} from "../user/actionType";
import { profileUpdate } from "../user/actions";
import axios from "./utils/axios";

const fetchProfileUpdateReducer = (name, password) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_PROFILE_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `api/profile/profile_update/`,
        { name, password },
        config
      );
      dispatch(profileUpdate(data));
    } catch (error) {
      dispatch({
        type: UPDATE_PROFILE_FAIL,
        payload:
          error.response && error.response.data.details
            ? error.response.data.details
            : error.message,
      });
    }
  };
};
export default fetchProfileUpdateReducer;
