import { APP_CREATE_FAIL, APP_CREATE_REQUEST } from "../app/actionType";
import { createApp } from "../app/actions";
import axios from "./utils/axios";

const fetchAppCreateReducer = (
  iconTitle,
  iconUrl,
  iconColor,
  folderId,
  iconImage,
  orderIcon
) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: APP_CREATE_REQUEST });

      const {
        login: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const formData = new FormData();
      formData.append("icon_title", iconTitle);
      formData.append("icon_url", iconUrl);
      formData.append("icon_color", iconColor);
      formData.append("icon_image", iconImage);
      formData.append("folder_id", folderId);
      formData.append("icon_order", orderIcon);

      const { data } = await axios.post(
        `api/app-icon/create-icon/`,
        formData,
        config
      );

      dispatch(createApp(data));
    } catch (error) {
      dispatch({
        type: APP_CREATE_FAIL,
        payload:
          error.response && error.response.data.details
            ? error.response.data.details
            : error.message,
      });
    }
  };
};

export default fetchAppCreateReducer;
