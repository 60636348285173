import React from "react";
import newsIcon from "../assets/news_icon.png";
import { useSelector } from "react-redux";

export default function NotificationDetails({ sendCloseAction }) {
  const announcementViewDetails = useSelector(
    (state) => state.announcementViewDetails
  );
  const { announcement } = announcementViewDetails || {};

  const handleClick = () => {
    sendCloseAction(false);
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <span className="close" onClick={handleClick}>
          &times;
        </span>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid gray",
          }}
        >
          <img src={newsIcon} alt="new-details" />
          <p
            style={{
              fontSize: "23px",
              fontWeight: "bold",
              marginLeft: "20px",
            }}
          >
            Announcements
          </p>
        </div>
        <div
          className="poppins-regular"
          style={{ maxHeight: "400px", overflowY: "auto" }} // Adjust the maxHeight as needed
        >
          <h2>{announcement?.title}</h2>
          <p
            style={{
              fontSize: "12px",
              width: "300px",
              padding: "5px",
              textAlign: "center",
              background: "yellow",
              borderTopRightRadius: "25px",
              borderBottomLeftRadius: "25px",
              textTransform: "uppercase",
            }}
          >
            Department: {announcement?.department}
          </p>
          <p>{announcement?.details}</p>
        </div>
      </div>
    </div>
  );
}
