import {
  ANNOUNCEMENT_CREATE_FAIL,
  ANNOUNCEMENT_CREATE_REQUEST,
} from "../announcement/actionType";
import { createAnnouncement } from "../announcement/actions";
import axios from "./utils/axios";

const fetchAnnouncementCreateReducer = (title, details, departmentName) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ANNOUNCEMENT_CREATE_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `api/announcement/create/`,
        { title, details, department_name: departmentName },
        config
      );
      dispatch(createAnnouncement(data));
    } catch (error) {
      dispatch({
        type: ANNOUNCEMENT_CREATE_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };
};
export default fetchAnnouncementCreateReducer;
