export const USER_LOGIN_REQUEST = "user/USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "user/USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "user/USER_LOGIN_FAIL";

export const USER_LOGOUT = "user/USER_LOGOUT";

export const PROFILE_VIEW_REQUEST = "user/PROFILE_VIEW_REQUEST";
export const PROFILE_VIEW_SUCCESS = "user/PROFILE_VIEW_SUCCESS";
export const PROFILE_VIEW_FAIL = "user/PROFILE_VIEW_FAIL";

export const PROFILE_DETAILS_REQUEST = "user/PROFILE_DETAILS_REQUEST";
export const PROFILE_DETAILS_SUCCESS = "user/PROFILE_DETAILS_SUCCESS";
export const PROFILE_DETAILS_FAIL = "user/PROFILE_DETAILS_FAIL";

export const UPDATE_PROFILE_REQUEST = "user/UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "user/UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "user/UPDATE_PROFILE_FAIL";

export const USER_LIST_REQUEST = "user/USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "user/USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "user/USER_LIST_FAIL";

export const USER_DETAILS_REQUEST = "user/USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "user/USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "user/USER_DETAILS_FAIL";

export const USER_UPDATE_REQUEST = "user/USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "user/USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "user/USER_UPDATE_FAIL";

export const USER_CREATE_REQUEST = "user/USER_CREATE_REQUEST";
export const USER_CREATE_SUCCESS = "user/USER_CREATE_SUCCESS";
export const USER_CREATE_FAIL = "user/USER_CREATE_FAIL";

export const USER_DELETE_REQUEST = "user/USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "user/USER_DELETE_SUCCESS";
export const USER_DELETE_FAIL = "user/USER_DELETE_FAIL";

export const USER_FORGET_PASSWORD_REQUEST = "user/USER_FORGET_PASSWORD_REQUEST";
export const USER_FORGET_PASSWORD_SUCCESS = "user/USER_FORGET_PASSWORD_SUCCESS";
export const USER_FORGET_PASSWORD_FAIL = "user/USER_FORGET_PASSWORD_FAIL";

export const USER_PASSWORD_VERIFY_REQUEST = "user/USER_PASSWORD_VERIFY_REQUEST";
export const USER_PASSWORD_VERIFY_SUCCESS = "user/USER_PASSWORD_VERIFY_SUCCESS";
export const USER_PASSWORD_VERIFY_FAIL = "user/USER_PASSWORD_VERIFY_FAIL";
