import {
  ANNOUNCEMENT_DETAILS_VIEW_FAIL,
  ANNOUNCEMENT_DETAILS_VIEW_REQUEST,
} from "../announcement/actionType";
import { announcementViewDetails } from "../announcement/actions";
import axios from "./utils/axios";

const fetchAnnouncementDetailsViewReducer = (announcement_id) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ANNOUNCEMENT_DETAILS_VIEW_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `api/announcement/get_details/${announcement_id}/`,
        config
      );
      dispatch(announcementViewDetails(data));
    } catch (error) {
      dispatch({
        type: ANNOUNCEMENT_DETAILS_VIEW_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };
};
export default fetchAnnouncementDetailsViewReducer;
