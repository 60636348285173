export const DEPARTMENT_CREATE_REQUEST = "department/DEPARTMENT_CREATE_REQUEST";
export const DEPARTMENT_CREATE_SUCCESS = "department/DEPARTMENT_CREATE_SUCCESS";
export const DEPARTMENT_CREATE_FAIL = "department/DEPARTMENT_CREATE_FAIL";

export const DEPARTMENT_UPDATE_REQUEST = "department/DEPARTMENT_UPDATE_REQUEST";
export const DEPARTMENT_UPDATE_SUCCESS = "department/DEPARTMENT_UPDATE_SUCCESS";
export const DEPARTMENT_UPDATE_FAIL = "department/DEPARTMENT_UPDATE_FAIL";

export const DEPARTMENT_DELETE_REQUEST = "department/DEPARTMENT_DELETE_REQUEST";
export const DEPARTMENT_DELETE_SUCCESS = "department/DEPARTMENT_DELETE_SUCCESS";
export const DEPARTMENT_DELETE_FAIL = "department/DEPARTMENT_DELETE_FAIL";

export const DEPARTMENT_LIST_VIEW_REQUEST =
  "department/DEPARTMENT_LIST_VIEW_REQUEST";
export const DEPARTMENT_LIST_VIEW_SUCCESS =
  "department/DEPARTMENT_LIST_VIEW_SUCCESS";
export const DEPARTMENT_LIST_VIEW_FAIL = "department/DEPARTMENT_LIST_VIEW_FAIL";
