import React, { useState } from "react";
import avatar from "../assets/avatar.png";
import edit from "../assets/edit.png";
import deleteImg from "../assets/delete.png";
import EditUser from "./EditUser";
import viewIcon from "../assets/view.png";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import fetchUserDeleteReducer from "../redux/thunk/fetchUserDeleteReducer";

export default function User({ user, index, onRefresh }) {
  const [editUserOpen, setEditUserOpen] = useState(false);
  const dispatch = useDispatch();
  const closeDetails = (newMessage) => {
    setEditUserOpen(newMessage);
    onRefresh(true);
  };
  const deleteUserHandler = (e) => {
    e.preventDefault();
    dispatch(fetchUserDeleteReducer(user?.email));
    onRefresh(true);
  };

  const editUserHandler = () => {
    setEditUserOpen(true);
  };
  return (
    <>
      <tr style={{ textAlign: "center" }} key={index}>
        <td style={{ border: "1px solid #ddd", padding: "8px" }}>
          {index + 1}
        </td>
        <td
          style={{
            border: "1px solid #ddd",
            padding: "8px",
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            style={{
              width: "35px",
              border: "2px solid #791615",
              height: "35px",
              borderRadius: "50%",
              overflow: "hidden",
            }}
            src={user?.profile_pic || avatar}
            alt="user-pic"
          />
        </td>
        <td style={{ border: "1px solid #ddd", padding: "8px" }}>
          {`${user?.first_name} ${user?.last_name}`}
        </td>
        <td style={{ border: "1px solid #ddd", padding: "8px" }}>
          {user?.email}
        </td>
        <td
          style={{
            border: "1px solid #ddd",
            padding: "8px",
            textTransform: "capitalize",
          }}
        >
          {user?.department || "No Department"}
        </td>
        <td
          style={{
            border: "1px solid #ddd",
            padding: "8px",
            position: "relative",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <img
              style={{
                width: "20px",
                height: "20px",
                cursor: "pointer",
                overflow: "hidden",
              }}
              onClick={editUserHandler}
              src={edit}
              alt="edit"
            />
            <img
              style={{
                width: "20px",
                height: "20px",
                cursor: "pointer",
                overflow: "hidden",
              }}
              src={deleteImg}
              alt="delete"
              onClick={deleteUserHandler}
            />
          </div>
        </td>
        <td
          style={{
            border: "1px solid #ddd",
            padding: "8px",
            textTransform: "capitalize",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Link to={`/users/${user?.email}`}>
              <img
                style={{
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                  overflow: "hidden",
                }}
                src={viewIcon}
                alt="view"
              />
            </Link>
          </div>
        </td>
      </tr>
      {editUserOpen && (
        <EditUser sendAppCloseAction={closeDetails} user_email={user?.email} />
      )}
    </>
  );
}
