import React from "react";

export default function Introduction({ name }) {
  return (
    <div
      style={{
        background: "#e4e3e5",
        padding: "20px 30px",
        borderRadius: "12px",
      }}
    >
      <h2>Hello, {name}</h2>
      <h3>Welcome to the Valley Hatchery Employee Dashboard</h3>
    </div>
  );
}
