import React, { useState } from "react";
import newsIcon from "../assets/news_icon.png";
import NotificationDetails from "./NotificationDetails";
import { useDispatch, useSelector } from "react-redux";
import edit from "../assets/edit.png";
import deleteButton from "../assets/delete.png";
import fetchAnnouncementDetailsViewReducer from "../redux/thunk/fetchAnnouncementDetailsViewReducer";
import { useLocation } from "react-router-dom";
import EditNotice from "./EditNotice";
import fetchAnnouncementDeleteReducer from "../redux/thunk/fetchAnnouncementDeleteReducer";

export default function News({ announcement, onRefresh }) {
  const dispatch = useDispatch();

  const [isEdit, setIsEdit] = useState(false);
  const [newsOpen, setNewsOpen] = useState(false);
  const login = useSelector((state) => state.login);
  const { userInfo } = login || {};
  const location = useLocation();

  let path = location.pathname;
  const newsDetailsHandler = (e) => {
    e.preventDefault();
    setNewsOpen(true);
    dispatch(fetchAnnouncementDetailsViewReducer(announcement?._id));
  };
  const closeDetails = (newMessage) => {
    setNewsOpen(newMessage);
    setIsEdit(newMessage);
    onRefresh(true);
  };
  const editNoticeHandler = () => {
    setIsEdit(true);
  };

  const deleteNoticeHandler = () => {
    dispatch(fetchAnnouncementDeleteReducer(announcement?._id));
    onRefresh(true);
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px 20px",
          borderBottom: "1px solid gray",
        }}
      >
        <div
          style={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <div
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              border: "2px solid #F44336",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={newsIcon}
              alt="news-icon"
              style={{ width: "25px", height: "25px" }}
            />
          </div>
          <p
            style={{
              fontWeight: "bolder",
              fontSize: "16px",
              marginLeft: "30px",
            }}
            className="cactus-classical-serif-regular"
          >
            {announcement?.title}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {path === "/home"
            ? ""
            : userInfo &&
              userInfo?.isAdmin && (
                <img
                  style={{
                    width: "20px",
                    height: "20px",
                    cursor: "pointer",
                    overflow: "hidden",
                    marginRight: "20px",
                  }}
                  onClick={editNoticeHandler}
                  src={edit}
                  alt="edit"
                />
              )}
          <div
            style={{
              width: "100px",
              height: "40px",
              background: "#0E6DB2",
              borderRadius: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={newsDetailsHandler}
          >
            <p style={{ textDecoration: "none", color: "white" }}>
              View Details
            </p>
          </div>
          {path === "/home"
            ? ""
            : userInfo &&
              userInfo?.isAdmin && (
                <img
                  style={{
                    width: "20px",
                    height: "20px",
                    cursor: "pointer",
                    overflow: "hidden",
                    marginLeft: "20px",
                  }}
                  onClick={deleteNoticeHandler}
                  src={deleteButton}
                  alt="delete"
                />
              )}
        </div>
      </div>
      {newsOpen && <NotificationDetails sendCloseAction={closeDetails} />}
      {isEdit && (
        <EditNotice
          editNoticeCloseAction={closeDetails}
          announcement_id={announcement?._id}
        />
      )}
    </>
  );
}
