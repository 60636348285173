import { ICON_DELETE_REQUEST, ICON_DELETE_SUCCESS } from "../app/actionType";
import { IconDelete } from "../app/actions";
import axios from "./utils/axios";

const fetchIconDeleteReducer = (iconID) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ICON_DELETE_REQUEST });

      const {
        login: { userInfo },
      } = getState();

      if (!userInfo || !userInfo.token) {
        throw new Error("User information or token not available.");
      }

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.delete(`api/app-icon/delete_icon/`, {
        headers: config.headers, // Pass headers directly to axios
        data: { icon_id: iconID }, // Use 'data' property to send data with axios.delete
      });

      dispatch(IconDelete(data));
    } catch (error) {
      dispatch({
        type: ICON_DELETE_SUCCESS,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };
};

export default fetchIconDeleteReducer;
