import React, { useEffect, useState } from "react";
import folder from "../assets/test.png";
import SelectedApp from "./SelectedApp";
import { useDispatch, useSelector } from "react-redux";
import fetchProfileUnassignAppListReducer from "../redux/thunk/fetchProfileUnassignAppListReducer";
import fetchAppAssignReducer from "../redux/thunk/fetchAppAssignReducer";

export default function AssignApp({ sendAppCloseAction, email }) {
  const [selectedApps, setSelectedApps] = useState([]);
  const dispatch = useDispatch();
  const profileUnassignApps = useSelector((state) => state.profileUnassignApps);
  const { apps } = profileUnassignApps || [];

  const handleSelectChange = (id) => {
    setSelectedApps((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((appId) => appId !== id)
        : [...prevSelected, id]
    );
  };

  const handleAssignClick = () => {
    dispatch(fetchAppAssignReducer(email, selectedApps));
    sendAppCloseAction(false);
    // Add your assignment logic here
  };

  const handleClick = () => {
    sendAppCloseAction(false);
  };
  useEffect(() => {
    dispatch(fetchProfileUnassignAppListReducer(email));
  }, [dispatch]);

  return (
    <div className="popup">
      <div className="popup-content">
        <span className="close" onClick={handleClick}>
          &times;
        </span>
        <h1 style={{ padding: "10px 40px" }}>Assign App Section </h1>
        <div className="app-container-box">
          {apps?.map((app) => (
            <SelectedApp
              key={app?._id}
              isFolder={false}
              title={app?.icon_title}
              image={app?.icon_image}
              color={app?.color}
              url={app?.icon_url}
              id={app?._id}
              selected={selectedApps.includes(app?._id)} // Make sure it is string comparison
              handleSelectChange={handleSelectChange}
            />
          ))}
        </div>
        <button
          onClick={handleAssignClick}
          className="app-create"
          style={{ margin: "40px", borderStyle: "none", width: "150px" }}
        >
          Assign
        </button>
      </div>
    </div>
  );
}
