import {
  DEPARTMENT_CREATE_FAIL,
  DEPARTMENT_CREATE_REQUEST,
  DEPARTMENT_CREATE_SUCCESS,
  DEPARTMENT_DELETE_FAIL,
  DEPARTMENT_DELETE_REQUEST,
  DEPARTMENT_DELETE_SUCCESS,
  DEPARTMENT_LIST_VIEW_FAIL,
  DEPARTMENT_LIST_VIEW_REQUEST,
  DEPARTMENT_LIST_VIEW_SUCCESS,
  DEPARTMENT_UPDATE_FAIL,
  DEPARTMENT_UPDATE_REQUEST,
  DEPARTMENT_UPDATE_SUCCESS,
} from "./actionType";

export const departmentListReducer = (state = [], action) => {
  switch (action.type) {
    case DEPARTMENT_LIST_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DEPARTMENT_LIST_VIEW_SUCCESS:
      return {
        loading: false,
        departments: action.payload,
        success: true,
        isError: false,
      };
    case DEPARTMENT_LIST_VIEW_FAIL:
      return {
        loading: false,
        departments: [],
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const createDepartmentReducer = (state = {}, action) => {
  switch (action.type) {
    case DEPARTMENT_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DEPARTMENT_CREATE_SUCCESS:
      return {
        loading: false,
        department: action.payload,
        success: true,
        isError: false,
      };
    case DEPARTMENT_CREATE_FAIL:
      return {
        loading: false,
        department: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const updateDepartmentReducer = (state = {}, action) => {
  switch (action.type) {
    case DEPARTMENT_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DEPARTMENT_UPDATE_SUCCESS:
      return {
        loading: false,
        department: action.payload,
        success: true,
        isError: false,
      };
    case DEPARTMENT_UPDATE_FAIL:
      return {
        loading: false,
        department: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const deleteDepartmentReducer = (state = {}, action) => {
  switch (action.type) {
    case DEPARTMENT_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DEPARTMENT_DELETE_SUCCESS:
      return {
        loading: false,
        department: action.payload,
        success: true,
        isError: false,
      };
    case DEPARTMENT_DELETE_FAIL:
      return {
        loading: false,
        department: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};
