import { combineReducers } from "redux";
import {
  forgetPasswordReducer,
  passwordVerifyReducer,
  profileDetailsReducer,
  profileUpdateReducer,
  profileViewReducer,
  userCreateReducer,
  userDeleteReducer,
  userDetailsReducer,
  userListReducer,
  userLoginReducer,
} from "./user/userReducer";
import {
  appAssignReducer,
  appCreateReducer,
  folderAssignReducer,
  folderCreateReducer,
  folderDetailsReducer,
  folderListReducer,
  iconDeleteReducer,
  iconUnassignReducer,
  myAppFolderDetailsReducer,
  myAppListReducer,
  profileAppListReducer,
  profileUnassignAppListReducer,
  profileUnassignFolderListReducer,
} from "./app/iconReducer";
import {
  createDepartmentReducer,
  deleteDepartmentReducer,
  departmentListReducer,
  updateDepartmentReducer,
} from "./department/departmentReducer";
import {
  announcementListReducer,
  announcementViewDetailsReducer,
  createAnnouncementReducer,
  deleteAnnouncementReducer,
  updateAnnouncementReducer,
} from "./announcement/announcementReducer";
const rootReducer = combineReducers({
  login: userLoginReducer,
  myAppList: myAppListReducer,
  userList: userListReducer,
  forgetPassword: forgetPasswordReducer,
  passwordVerify: passwordVerifyReducer,
  userDetails: userDetailsReducer,
  userCreate: userCreateReducer,
  userDelete: userDeleteReducer,
  profileView: profileViewReducer,
  profileUpdate: profileUpdateReducer,
  profileDetails: profileDetailsReducer,
  profileAppList: profileAppListReducer,
  appFolderDetails: myAppFolderDetailsReducer,
  folderDetails: folderDetailsReducer,
  // Department
  departmentCreate: createDepartmentReducer,
  departmentList: departmentListReducer,
  departmentUpdate: updateDepartmentReducer,
  departmentDelete: deleteDepartmentReducer,
  // Announcement
  announcementCreate: createAnnouncementReducer,
  announcementList: announcementListReducer,
  announcementUpdate: updateAnnouncementReducer,
  announcementViewDetails: announcementViewDetailsReducer,
  announcementDelete: deleteAnnouncementReducer,
  // Folder
  folderCreate: folderCreateReducer,
  folderList: folderListReducer,
  profileUnassignFolders: profileUnassignFolderListReducer,
  folderAssign: folderAssignReducer,
  // App
  appCreate: appCreateReducer,
  profileUnassignApps: profileUnassignAppListReducer,
  appAssign: appAssignReducer,
  // Icon
  iconUnassign: iconUnassignReducer,
  iconDelete: iconDeleteReducer,
});

export default rootReducer;
