import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fetchDepartmentListViewReducer from "../redux/thunk/fetchDepartmentListViewReducer";
import fetchUserCreateReducer from "../redux/thunk/fetchUserCreateReducer";
import Loading from "./ui/Loading";
import Error from "./ui/Error";
import Success from "./ui/Success";

export default function CreateUser({ sendAppCloseAction, createOnRefresh }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const departmentList = useSelector((state) => state.departmentList);
  const userCreate = useSelector((state) => state.userCreate);
  const { loading, isError, error, success } = userCreate || {};
  const { departments } = departmentList || {};
  const dispatch = useDispatch();
  const createHanlder = (e) => {
    e.preventDefault();
    console.log("this");
    dispatch(
      fetchUserCreateReducer(
        firstName,
        lastName,
        email,
        departmentName,
        password
      )
    );
    // sendAppCloseAction(false);
    createOnRefresh(true);
  };
  const handleClick = () => {
    sendAppCloseAction(false);
    createOnRefresh(true);
  };
  const cancelHandler = () => {
    sendAppCloseAction(false);
    createOnRefresh(true);
  };
  useEffect(() => {
    dispatch(fetchDepartmentListViewReducer);
  }, [dispatch]);
  return (
    <div className="popup">
      <div className="popup-content">
        <span className="close" onClick={handleClick}>
          &times;
        </span>
        <h1 style={{ padding: "10px 40px" }}>Create User Section </h1>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {loading && <Loading />}
          {isError && <Error message={error} />}
          {success && <Success message="User Create Successfully" />}
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <form
            style={{
              width: "60%",
              position: "relative",
            }}
            onSubmit={createHanlder}
          >
            <div
              style={{
                margin: "30px 0px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label style={{ fontSize: "20px" }}>Enter First Name</label>
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                style={{ padding: "10px 20px", width: "220px" }}
              />
            </div>
            <div
              style={{
                margin: "30px 0px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label style={{ fontSize: "20px" }}>Enter Last Name</label>
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                style={{ padding: "10px 20px", width: "220px" }}
              />
            </div>
            <div
              style={{
                margin: "30px 0px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label style={{ fontSize: "20px" }}>Enter Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ padding: "10px 20px", width: "220px" }}
              />
            </div>
            <div
              style={{
                margin: "30px 0px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label style={{ fontSize: "20px" }}>Enter Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{ padding: "10px 20px", width: "220px" }}
              />
            </div>
            <div
              style={{
                margin: "30px 0px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label style={{ fontSize: "20px" }}>Department</label>
              <select
                value={departmentName}
                onChange={(e) => setDepartmentName(e.target.value)}
                style={{
                  padding: "10px 20px",
                  width: "262px",
                  fontSize: "16px",
                  textTransform: "capitalize",
                }}
              >
                <option value={null}>Select Department</option>
                {departments?.map((dept) => (
                  <option key={dept.id} value={dept.department_name}>
                    {dept.department_name}
                  </option>
                ))}
              </select>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                margin: "40px 0px",
              }}
            >
              <button
                type="submit"
                style={{
                  width: "140px",
                  height: "35px",
                  borderRadius: "10px",
                  borderStyle: "none",
                  background: "#486776",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                Add User
              </button>
              <button
                style={{
                  width: "140px",
                  height: "35px",
                  borderRadius: "10px",
                  borderStyle: "none",
                  background: "red",
                  color: "white",
                  cursor: "pointer",
                }}
                onClick={cancelHandler}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
