import { profileView } from "../user/actions";
import { PROFILE_VIEW_FAIL, PROFILE_VIEW_REQUEST } from "../user/actionType";
import axios from "./utils/axios";

const fetchProfileView = async (dispatch, getState) => {
  try {
    dispatch({ type: PROFILE_VIEW_REQUEST });
    const {
      login: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`api/profile/my_profile/`, config);
    dispatch(profileView(data));
  } catch (error) {
    dispatch({
      type: PROFILE_VIEW_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
export default fetchProfileView;
