import {
  USER_FORGET_PASSWORD_FAIL,
  USER_FORGET_PASSWORD_REQUEST,
} from "../user/actionType";
import { forgetPassword } from "../user/actions";
import axios from "./utils/axios";

const fetchForgetPasswordReducer = (email) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_FORGET_PASSWORD_REQUEST });
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const { data } = await axios.post(
        `api/auth/forget-password/`,
        { email },
        config
      );
      dispatch(forgetPassword(data));
    } catch (error) {
      dispatch({
        type: USER_FORGET_PASSWORD_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };
};
export default fetchForgetPasswordReducer;
