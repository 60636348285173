import {
  APP_ASSIGN_SUCCESS,
  APP_CREATE_SUCCESS,
  FOLDER_ASSIGN_SUCCESS,
  FOLDER_CREATE_SUCCESS,
  FOLDER_DETAILS_SUCCESS,
  FOLDER_LIST_SUCCESS,
  ICON_DELETE_SUCCESS,
  ICON_UNASSIGN_SUCCESS,
  MY_APP_FOLDER_DETAILS_SUCCESS,
  MY_APP_LIST_SUCCESS,
  PROFILE_APP_LIST_SUCCESS,
  PROFILE_UNASSIGN_APP_LIST_SUCCESS,
  PROFILE_UNASSIGN_FOLDER_LIST_SUCCESS,
} from "./actionType";

export const myapplist = (data) => {
  return {
    type: MY_APP_LIST_SUCCESS,
    payload: data,
  };
};

export const folderList = (data) => {
  return {
    type: FOLDER_LIST_SUCCESS,
    payload: data,
  };
};

export const myAppFolderDetails = (data) => {
  return {
    type: MY_APP_FOLDER_DETAILS_SUCCESS,
    payload: data,
  };
};

export const folderDetails = (data) => {
  return {
    type: FOLDER_DETAILS_SUCCESS,
    payload: data,
  };
};

export const createFolder = (data) => {
  return {
    type: FOLDER_CREATE_SUCCESS,
    payload: data,
  };
};

export const createApp = (data) => {
  return {
    type: APP_CREATE_SUCCESS,
    payload: data,
  };
};

export const profileApp = (data) => {
  return {
    type: PROFILE_APP_LIST_SUCCESS,
    payload: data,
  };
};

export const unassignApps = (data) => {
  return {
    type: PROFILE_UNASSIGN_APP_LIST_SUCCESS,
    payload: data,
  };
};

export const unassignFolders = (data) => {
  return {
    type: PROFILE_UNASSIGN_FOLDER_LIST_SUCCESS,
    payload: data,
  };
};

export const assignFolder = (data) => {
  return {
    type: FOLDER_ASSIGN_SUCCESS,
    payload: data,
  };
};

export const assignApp = (data) => {
  return {
    type: APP_ASSIGN_SUCCESS,
    payload: data,
  };
};
export const unassignIcon = (data) => {
  return {
    type: ICON_UNASSIGN_SUCCESS,
    payload: data,
  };
};
export const IconDelete = (data) => {
  return {
    type: ICON_DELETE_SUCCESS,
    payload: data,
  };
};
