import React, { useEffect, useState } from "react";
import News from "../News";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import fetchAnnouncementListViewReducer from "../../redux/thunk/fetchAnnouncementViewListReducer";
import Notice from "../Notice";
import Error from "../ui/Error";

export default function Notification() {
  const [addNoticeOpen, setAddNoticeOpen] = useState(false);
  const [formClosed, setFormClosed] = useState(false);
  const [refresh, setRefresh] = useState(false);
  let navigate = useNavigate();
  const announcementList = useSelector((state) => state.announcementList);
  const { announcements, loading, isError, error } = announcementList || {};
  const login = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const { userInfo } = login || {};
  const addnoticeHandler = () => {
    setAddNoticeOpen(true);
  };
  const closeDetails = (newMessage) => {
    setAddNoticeOpen(newMessage);
    setFormClosed(true);
  };
  const handleRefresh = (message) => {
    setRefresh(message);
  };
  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    }
    dispatch(fetchAnnouncementListViewReducer);
    setFormClosed(false);
    setRefresh(false);
  }, [navigate, userInfo, dispatch, formClosed, refresh]);
  return (
    <>
      <div style={{ margin: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0px 30px",
          }}
        >
          <div>
            <h1>Annoucements</h1>
            <p
              style={{
                fontSize: "12px",
                margin: "20px 5px",
                fontStyle: "italic",
              }}
            >
              Order By: New News to Old News
            </p>
          </div>
          {userInfo && userInfo?.isAdmin && (
            <div className="notice-create" onClick={addnoticeHandler}>
              Create Annoucements
            </div>
          )}
        </div>
        <div
          style={{
            height: "auto",
            borderRadius: "12px",
            background: "#e4e3e5",
            padding: "10px",
          }}
        >
          {announcements?.length > 0
            ? announcements?.map((announcement, index) => (
                <News
                  key={index}
                  announcement={announcement}
                  onRefresh={handleRefresh}
                />
              ))
            : !isError && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  No Data Found
                </div>
              )}
          {isError && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Error message={error} />
            </div>
          )}
        </div>
      </div>

      {addNoticeOpen && <Notice sendNoticeCloseAction={closeDetails} />}
    </>
  );
}
