import { USER_DELETE_FAIL, USER_DELETE_REQUEST } from "../user/actionType";
import { userDelete } from "../user/actions";
import axios from "./utils/axios";

const fetchUserDeleteReducer = (email) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: USER_DELETE_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.delete(
        `api/auth/delete_user/${email}/`,
        config
      );
      dispatch(userDelete(data));
    } catch (error) {
      dispatch({
        type: USER_DELETE_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };
};
export default fetchUserDeleteReducer;
