import React from "react";
import notfound from "../../assets/notFound.jpg";

export default function NotFound() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p
        style={{
          color: "red",
          textAlign: "center",
          fontSize: "38px",
          fontWeight: "bold",
        }}
      >
        Page not found
      </p>
      <img
        src={notfound}
        alt="404 Error. Page not found."
        className="image"
        style={{ width: "600px", height: "350px" }}
      />
    </div>
  );
}
