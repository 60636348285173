import React, { useEffect, useState } from "react";
import logo from "../assets/logo.png";
import avatar from "../assets/avatar.png";
import notification from "../assets/notification.svg";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/user/actions";

export default function Header() {
  const [navAvatar, setNavAvatar] = useState(false);
  const location = useLocation();
  const login = useSelector((state) => state.login);
  const { loading, isError, userInfo, error } = login || {};
  // console.log(userInfo);
  const { pathname } = location;
  const dispatch = useDispatch();
  const logoutHandler = (e) => {
    e.preventDefault();
    dispatch(logout);
    setNavAvatar(false);
  };
  console.log(userInfo?.profile_pic);
  useEffect(() => {
    if (userInfo) {
      setNavAvatar(true);
    }
  }, [pathname, setNavAvatar, userInfo]);

  return (
    <div
      style={{
        height: navAvatar ? "10vh" : "30vh",
        display: navAvatar ? "flex" : "",
        marginBottom: "30px",
      }}
    >
      <div
        style={{
          width: navAvatar ? "20%" : "100%",
          height: "100%",
          display: navAvatar ? "flex" : "",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <img
          src={logo}
          alt="logo"
          style={{
            height: navAvatar ? "150px" : "280px",
            width: navAvatar ? "200px" : "350px",
          }}
        />
      </div>
      {navAvatar && (
        <div
          style={{
            width: "80%",
            height: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div>
            <Link to="/home" className="navbar">
              Home
            </Link>
            <Link to="/application" className="navbar">
              Applications
            </Link>
            <Link to="/announcement" className="navbar">
              Announcements
            </Link>
            {userInfo && userInfo?.isAdmin ? (
              <Link to="/users" className="navbar">
                Users
              </Link>
            ) : (
              ""
            )}
            {userInfo && userInfo?.isAdmin ? (
              <Link to="/departments" className="navbar">
                Department
              </Link>
            ) : (
              ""
            )}
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <Link to="/">
              <img
                src={notification}
                alt="notification_icon"
                style={{ width: "25px", height: "25px", color: "#791615" }}
              />
            </Link>
            <Link to="/profile" style={{ cursor: "pointer" }}>
              <img
                src={userInfo?.profile_pic || avatar}
                alt="avatar"
                style={{ width: "35px", height: "35px", borderRadius: "50%" }}
              />
            </Link>
            <div
              style={{
                textDecoration: "none",
                color: "#791615",
                cursor: "pointer",
              }}
              onClick={logoutHandler}
            >
              Logout
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
