import React, { useEffect, useState } from "react";
import axios from "../../redux/thunk/utils/axios";
import profile_pic from "../../assets/avatar.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import fetchProfileView from "../../redux/thunk/fetchProfileView";
import fetchProfileUpdateReducer from "../../redux/thunk/fetchProfileUpdateReducer";
import Loading from "../ui/Loading";
import Success from "../ui/Success";

export default function Profile() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const login = useSelector((state) => state.login);
  const profileView = useSelector((state) => state.profileView);
  const { profile } = profileView || {};
  const profileUpdate = useSelector((state) => state.profileUpdate);
  const { loading: updateLoading, success } = profileUpdate || {};
  const [profilePic, setProfilePic] = useState(profile?.profile_pic);
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(profile?.name);
  const [password, setPassword] = useState("");
  console.log(profile);
  const { userInfo } = login || {};
  useEffect(() => {
    if (userInfo) {
      dispatch(fetchProfileView);
    } else {
      navigate("/");
    }
  }, [navigate, userInfo, dispatch]);
  useEffect(() => {
    if (profile) {
      setProfilePic(profile?.profile_pic);
      setName(profile?.name);
    }
    setLoading(updateLoading);
  }, [profile, updateLoading]);

  const handleImageChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("profile_pic", file);

      try {
        // Set loading state to true when the upload starts
        setLoading(true);

        // Make a PUT request to the server to upload the image
        const response = await axios.put(
          "/api/profile/profile_change/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${userInfo.token}`,
            },
          }
        );

        // Assuming the server responds with the URL of the uploaded image
        setProfilePic(response.data.profile_pic);

        // Dispatch an action to fetch and update the profile
        dispatch(fetchProfileView);

        // Set loading state to false when the upload completes
        setLoading(false);
      } catch (error) {
        console.error("Error uploading image:", error);

        // Set loading state to false if there's an error
        setLoading(false);
      }
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    setIsEditing(false);
    dispatch(fetchProfileUpdateReducer(name, password));
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        height: "100vh",
        padding: "30px 0",
        backgroundColor: "#f0f0f0",
        textTransform: "capitalize",
      }}
    >
      {loading && <Loading />}
      {success && <Success message="Profile Updated Successfully" />}
      <div
        style={{
          width: "150px",
          height: "150px",
          border: "1px double gray",
          borderRadius: "50%",
          marginTop: "10px",
          marginBottom: "20px",
          overflow: "hidden",
        }}
      >
        <img
          src={profilePic || profile_pic}
          alt="profile_pic"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </div>
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        style={{ marginBottom: "20px" }}
      />

      <div style={{ marginBottom: "20px" }}>
        <label>Name: </label>
        {isEditing ? (
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        ) : (
          <span>{name || "No name"}</span>
        )}
      </div>

      <div style={{ marginBottom: "20px" }}>
        <label>Department: </label>

        <span>{profile?.department || "No Department"}</span>
      </div>

      <div style={{ marginBottom: "20px" }}>
        <label>Password: </label>
        {isEditing ? (
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            minLength={8}
          />
        ) : (
          <span>{"******"}</span>
        )}
      </div>

      {isEditing ? (
        <button
          onClick={handleSave}
          style={{ padding: "10px 20px" }}
          className="profile-button"
        >
          Save
        </button>
      ) : (
        <button
          onClick={handleEdit}
          style={{ padding: "10px 20px" }}
          className="profile-button"
        >
          Edit
        </button>
      )}
    </div>
  );
}
