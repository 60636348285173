import {
  DEPARTMENT_CREATE_SUCCESS,
  DEPARTMENT_DELETE_SUCCESS,
  DEPARTMENT_LIST_VIEW_SUCCESS,
  DEPARTMENT_UPDATE_SUCCESS,
} from "./actionType";

export const createDepartment = (data) => {
  return {
    type: DEPARTMENT_CREATE_SUCCESS,
    payload: data,
  };
};

export const updateDepartment = (data) => {
  return {
    type: DEPARTMENT_UPDATE_SUCCESS,
    payload: data,
  };
};

export const deleteDepartment = (data) => {
  return {
    type: DEPARTMENT_DELETE_SUCCESS,
    payload: data,
  };
};

export const departmentListView = (data) => {
  return {
    type: DEPARTMENT_LIST_VIEW_SUCCESS,
    payload: data,
  };
};
