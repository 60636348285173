import { folderDetails } from "../app/actions";
import { FOLDER_DETAILS_FAIL, FOLDER_DETAILS_REQUEST } from "../app/actionType";
import axios from "./utils/axios";

const fetchFolderDetailsReducer = (folderId) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: FOLDER_DETAILS_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `api/app-icon/folder-details/${folderId}/`,
        config
      );
      dispatch(folderDetails(data));
    } catch (error) {
      dispatch({
        type: FOLDER_DETAILS_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };
};
export default fetchFolderDetailsReducer;
