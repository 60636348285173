import { myAppFolderDetails } from "../app/actions";
import {
  MY_APP_FOLDER_DETAILS_FAIL,
  MY_APP_FOLDER_DETAILS_REQUEST,
} from "../app/actionType";
import axios from "./utils/axios";

const fetchAppFolderDetailsReducer = (folderId) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: MY_APP_FOLDER_DETAILS_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `api/app-icon/my-app-folder-details/${folderId}/`,
        config
      );
      dispatch(myAppFolderDetails(data));
    } catch (error) {
      dispatch({
        type: MY_APP_FOLDER_DETAILS_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };
};
export default fetchAppFolderDetailsReducer;
