import {
  USER_PASSWORD_VERIFY_FAIL,
  USER_PASSWORD_VERIFY_REQUEST,
} from "../user/actionType";
import { passwordVerify } from "../user/actions";
import axios from "./utils/axios";

const fetchPasswordVerifyReducer = (email, otp) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_PASSWORD_VERIFY_REQUEST });
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      //   const convertEmail = email.split("@")[0];
      const { data } = await axios.post(
        "api/auth/password-verify/",
        { email, otp },
        config
      );
      dispatch(passwordVerify(data));
      localStorage.setItem(
        "vh_employee_dashboard_userInfo",
        JSON.stringify(data)
      );
    } catch (error) {
      dispatch({
        type: USER_PASSWORD_VERIFY_FAIL,
        payload:
          error.response && error.response.data.details
            ? error.response.data.details
            : error.message,
      });
    }
  };
};
export default fetchPasswordVerifyReducer;
