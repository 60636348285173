export default function Error({ message }) {
  return (
    <div
      style={{
        color: "red",
        marginTop: "15px",
      }}
    >
      {message}
    </div>
  );
}
