import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import fetchUserLogin from "../../redux/thunk/fetchLogin";
import Loading from "../ui/Loading";
import Error from "../ui/Error";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const login = useSelector((state) => state.login);
  const { loading, isError, userInfo, error } = login || {};
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(fetchUserLogin(email, password));
  };
  useEffect(() => {
    if (userInfo) {
      navigate("/home");
    }
  }, [navigate, userInfo]);
  return (
    <div>
      {loading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loading />
        </div>
      )}
      <h1
        style={{ textAlign: "center", color: "791615" }}
        className="poetsen-one-regular"
      >
        Employee Dashboard
      </h1>
      <form
        onSubmit={submitHandler}
        style={{
          width: "630px",
          padding: "10px ",
          margin: "0px auto",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "70%",
            justifyContent: "space-between",
            margin: "20px auto",
          }}
        >
          <label htmlFor="email" className="login-label">
            Email:
          </label>
          <input
            type="email"
            placeholder="Enter Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="login-input"
            autoComplete="email"
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "70%",
            justifyContent: "space-between",
            margin: "20px auto",
          }}
        >
          <label htmlFor="password" className="login-label">
            Password:
          </label>
          <input
            type="password"
            placeholder="Enter Your Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="login-input"
            autoComplete="current-password"
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "70%",
            margin: "20px auto",
          }}
        >
          <button type="submit" className="login-button">
            Login
          </button>
          <Link to="/forget-password" className="forgot-link">
            Forgot Account?
          </Link>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          {isError && <Error message={error} />}
        </div>
      </form>
    </div>
  );
}
