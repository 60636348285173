import {
  APP_ASSIGN_FAIL,
  APP_ASSIGN_REQUEST,
  APP_ASSIGN_SUCCESS,
  APP_CREATE_FAIL,
  APP_CREATE_REQUEST,
  APP_CREATE_SUCCESS,
  FOLDER_ASSIGN_FAIL,
  FOLDER_ASSIGN_REQUEST,
  FOLDER_ASSIGN_SUCCESS,
  FOLDER_CREATE_FAIL,
  FOLDER_CREATE_REQUEST,
  FOLDER_CREATE_SUCCESS,
  FOLDER_DETAILS_FAIL,
  FOLDER_DETAILS_REQUEST,
  FOLDER_DETAILS_SUCCESS,
  FOLDER_LIST_FAIL,
  FOLDER_LIST_REQUEST,
  FOLDER_LIST_SUCCESS,
  ICON_DELETE_FAIL,
  ICON_DELETE_REQUEST,
  ICON_DELETE_SUCCESS,
  ICON_UNASSIGN_FAIL,
  ICON_UNASSIGN_REQUEST,
  ICON_UNASSIGN_SUCCESS,
  MY_APP_FOLDER_DETAILS_FAIL,
  MY_APP_FOLDER_DETAILS_REQUEST,
  MY_APP_FOLDER_DETAILS_SUCCESS,
  MY_APP_LIST_FAIL,
  MY_APP_LIST_REQUEST,
  MY_APP_LIST_SUCCESS,
  PROFILE_APP_LIST_FAIL,
  PROFILE_APP_LIST_REQUEST,
  PROFILE_APP_LIST_SUCCESS,
  PROFILE_UNASSIGN_APP_LIST_FAIL,
  PROFILE_UNASSIGN_APP_LIST_REQUEST,
  PROFILE_UNASSIGN_APP_LIST_SUCCESS,
  PROFILE_UNASSIGN_FOLDER_LIST_FAIL,
  PROFILE_UNASSIGN_FOLDER_LIST_REQUEST,
  PROFILE_UNASSIGN_FOLDER_LIST_SUCCESS,
} from "./actionType";

export const myAppListReducer = (state = [], action) => {
  switch (action.type) {
    case MY_APP_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case MY_APP_LIST_SUCCESS:
      return {
        loading: false,
        folders: action.payload,
        success: true,
        isError: false,
      };
    case MY_APP_LIST_FAIL:
      return {
        loading: false,
        folders: [],
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const folderListReducer = (state = [], action) => {
  switch (action.type) {
    case FOLDER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FOLDER_LIST_SUCCESS:
      return {
        loading: false,
        folders: action.payload,
        success: true,
        isError: false,
      };
    case FOLDER_LIST_FAIL:
      return {
        loading: false,
        folders: [],
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const profileAppListReducer = (state = [], action) => {
  switch (action.type) {
    case PROFILE_APP_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PROFILE_APP_LIST_SUCCESS:
      return {
        loading: false,
        folders: action.payload,
        success: true,
        isError: false,
      };
    case PROFILE_APP_LIST_FAIL:
      return {
        loading: false,
        folders: [],
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};
export const profileUnassignAppListReducer = (state = [], action) => {
  switch (action.type) {
    case PROFILE_UNASSIGN_APP_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PROFILE_UNASSIGN_APP_LIST_SUCCESS:
      return {
        loading: false,
        apps: action.payload,
        success: true,
        isError: false,
      };
    case PROFILE_UNASSIGN_APP_LIST_FAIL:
      return {
        loading: false,
        apps: [],
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};
export const profileUnassignFolderListReducer = (state = [], action) => {
  switch (action.type) {
    case PROFILE_UNASSIGN_FOLDER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PROFILE_UNASSIGN_FOLDER_LIST_SUCCESS:
      return {
        loading: false,
        folders: action.payload,
        success: true,
        isError: false,
      };
    case PROFILE_UNASSIGN_FOLDER_LIST_FAIL:
      return {
        loading: false,
        folders: [],
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const folderAssignReducer = (state = {}, action) => {
  switch (action.type) {
    case FOLDER_ASSIGN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FOLDER_ASSIGN_SUCCESS:
      return {
        loading: false,
        folders: action.payload,
        success: true,
        isError: false,
      };
    case FOLDER_ASSIGN_FAIL:
      return {
        loading: false,
        folders: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const appAssignReducer = (state = {}, action) => {
  switch (action.type) {
    case APP_ASSIGN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case APP_ASSIGN_SUCCESS:
      return {
        loading: false,
        apps: action.payload,
        success: true,
        isError: false,
      };
    case APP_ASSIGN_FAIL:
      return {
        loading: false,
        apps: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const myAppFolderDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case MY_APP_FOLDER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case MY_APP_FOLDER_DETAILS_SUCCESS:
      return {
        loading: false,
        apps: action.payload,
        success: true,
        isError: false,
      };
    case MY_APP_FOLDER_DETAILS_FAIL:
      return {
        loading: false,
        apps: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const folderDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case FOLDER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FOLDER_DETAILS_SUCCESS:
      return {
        loading: false,
        folder: action.payload,
        success: true,
        isError: false,
      };
    case FOLDER_DETAILS_FAIL:
      return {
        loading: false,
        folder: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const folderCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case FOLDER_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FOLDER_CREATE_SUCCESS:
      return {
        loading: false,
        folder: action.payload,
        success: true,
        isError: false,
      };
    case FOLDER_CREATE_FAIL:
      return {
        loading: false,
        folder: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const appCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case APP_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case APP_CREATE_SUCCESS:
      return {
        loading: false,
        app: action.payload,
        success: true,
        isError: false,
      };
    case APP_CREATE_FAIL:
      return {
        loading: false,
        app: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const iconUnassignReducer = (state = {}, action) => {
  switch (action.type) {
    case ICON_UNASSIGN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ICON_UNASSIGN_SUCCESS:
      return {
        loading: false,
        icon: action.payload,
        success: true,
        isError: false,
      };
    case ICON_UNASSIGN_FAIL:
      return {
        loading: false,
        icon: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const iconDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ICON_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ICON_DELETE_SUCCESS:
      return {
        loading: false,
        icon: action.payload,
        success: true,
        isError: false,
      };
    case ICON_DELETE_FAIL:
      return {
        loading: false,
        icon: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};
