import {
  PROFILE_DETAILS_SUCCESS,
  PROFILE_VIEW_SUCCESS,
  UPDATE_PROFILE_SUCCESS,
  USER_CREATE_SUCCESS,
  USER_DELETE_SUCCESS,
  USER_DETAILS_SUCCESS,
  USER_FORGET_PASSWORD_SUCCESS,
  USER_LIST_SUCCESS,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_PASSWORD_VERIFY_SUCCESS,
  USER_UPDATE_SUCCESS,
} from "./actionType";

export const login = (data) => {
  return {
    type: USER_LOGIN_SUCCESS,
    payload: data,
  };
};

export const logout = (dispatch) => {
  dispatch({
    type: USER_LOGOUT,
  });
  localStorage.removeItem("vh_employee_dashboard_userInfo");
};

export const profileView = (data) => {
  return {
    type: PROFILE_VIEW_SUCCESS,
    payload: data,
  };
};

export const userList = (data) => {
  return {
    type: USER_LIST_SUCCESS,
    payload: data,
  };
};

export const userCreate = (data) => {
  return {
    type: USER_CREATE_SUCCESS,
    payload: data,
  };
};

export const userUpdate = (data) => {
  return {
    type: USER_UPDATE_SUCCESS,
    payload: data,
  };
};

export const userDelete = (data) => {
  return {
    type: USER_DELETE_SUCCESS,
    payload: data,
  };
};

export const userDetails = (data) => {
  return {
    type: USER_DETAILS_SUCCESS,
    payload: data,
  };
};

export const profileUpdate = (data) => {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    payload: data,
  };
};

export const profileDetails = (data) => {
  return {
    type: PROFILE_DETAILS_SUCCESS,
    payload: data,
  };
};

export const forgetPassword = (data) => {
  return {
    type: USER_FORGET_PASSWORD_SUCCESS,
    payload: data,
  };
};

export const passwordVerify = (data) => {
  return {
    type: USER_PASSWORD_VERIFY_SUCCESS,
    payload: data,
  };
};
