import { USER_UPDATE_FAIL, USER_UPDATE_REQUEST } from "../user/actionType";
import { userUpdate } from "../user/actions";
import axios from "./utils/axios";

const fetchUserUpdateReducer = (
  firstName,
  lastName,
  email,
  department,
  user_email
) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: USER_UPDATE_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `api/auth/user-update/${user_email}/`,
        {
          first_name: firstName,
          last_name: lastName,
          email,
          department_name: department,
        },
        config
      );
      dispatch(userUpdate(data));
    } catch (error) {
      dispatch({
        type: USER_UPDATE_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };
};
export default fetchUserUpdateReducer;
