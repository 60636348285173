export const MY_APP_LIST_REQUEST = "app/MY_APP_LIST_REQUEST";
export const MY_APP_LIST_SUCCESS = "app/MY_APP_LIST_SUCCESS";
export const MY_APP_LIST_FAIL = "app/MY_APP_LIST_FAIL";

export const APP_CREATE_REQUEST = "app/APP_CREATE_REQUEST";
export const APP_CREATE_SUCCESS = "app/APP_CREATE_SUCCESS";
export const APP_CREATE_FAIL = "app/APP_CREATE_FAIL";

export const MY_APP_FOLDER_DETAILS_REQUEST =
  "app/MY_APP_FOLDER_DETAILS_REQUEST";
export const MY_APP_FOLDER_DETAILS_SUCCESS =
  "app/MY_APP_FOLDER_DETAILS_SUCCESS";
export const MY_APP_FOLDER_DETAILS_FAIL = "app/MY_APP_FOLDER_DETAILS_FAIL";

export const FOLDER_DETAILS_REQUEST = "app/FOLDER_DETAILS_REQUEST";
export const FOLDER_DETAILS_SUCCESS = "app/FOLDER_DETAILS_SUCCESS";
export const FOLDER_DETAILS_FAIL = "app/FOLDER_DETAILS_FAIL";

export const FOLDER_CREATE_REQUEST = "app/FOLDER_CREATE_REQUEST";
export const FOLDER_CREATE_SUCCESS = "app/FOLDER_CREATE_SUCCESS";
export const FOLDER_CREATE_FAIL = "app/FOLDER_CREATE_FAIL";

export const FOLDER_LIST_REQUEST = "app/FOLDER_LIST_REQUEST";
export const FOLDER_LIST_SUCCESS = "app/FOLDER_LIST_SUCCESS";
export const FOLDER_LIST_FAIL = "app/FOLDER_LIST_FAIL";

export const PROFILE_APP_LIST_REQUEST = "app/PROFILE_APP_LIST_REQUEST";
export const PROFILE_APP_LIST_SUCCESS = "app/PROFILE_APP_LIST_SUCCESS";
export const PROFILE_APP_LIST_FAIL = "app/PROFILE_APP_LIST_FAIL";

export const PROFILE_UNASSIGN_APP_LIST_REQUEST =
  "app/PROFILE_UNASSIGN_APP_LIST_REQUEST";
export const PROFILE_UNASSIGN_APP_LIST_SUCCESS =
  "app/PROFILE_UNASSIGN_APP_LIST_SUCCESS";
export const PROFILE_UNASSIGN_APP_LIST_FAIL =
  "app/PROFILE_UNASSIGN_APP_LIST_FAIL";

export const PROFILE_UNASSIGN_FOLDER_LIST_REQUEST =
  "app/PROFILE_UNASSIGN_FOLDER_LIST_REQUEST";
export const PROFILE_UNASSIGN_FOLDER_LIST_SUCCESS =
  "app/PROFILE_UNASSIGN_FOLDER_LIST_SUCCESS";
export const PROFILE_UNASSIGN_FOLDER_LIST_FAIL =
  "app/PROFILE_UNASSIGN_FOLDER_LIST_FAIL";

export const FOLDER_ASSIGN_REQUEST = "app/FOLDER_ASSIGN_REQUEST";
export const FOLDER_ASSIGN_SUCCESS = "app/FOLDER_ASSIGN_SUCCESS";
export const FOLDER_ASSIGN_FAIL = "app/FOLDER_ASSIGN_FAIL";

export const APP_ASSIGN_REQUEST = "app/APP_ASSIGN_REQUEST";
export const APP_ASSIGN_SUCCESS = "app/APP_ASSIGN_SUCCESS";
export const APP_ASSIGN_FAIL = "app/APP_ASSIGN_FAIL";

export const ICON_UNASSIGN_REQUEST = "app/ICON_UNASSIGN_REQUEST";
export const ICON_UNASSIGN_SUCCESS = "app/ICON_UNASSIGN_SUCCESS";
export const ICON_UNASSIGN_FAIL = "app/ICON_UNASSIGN_FAIL";

export const ICON_DELETE_REQUEST = "app/ICON_DELETE_REQUEST";
export const ICON_DELETE_SUCCESS = "app/ICON_DELETE_SUCCESS";
export const ICON_DELETE_FAIL = "app/ICON_DELETE_FAIL";
