import {
  ANNOUNCEMENT_CREATE_SUCCESS,
  ANNOUNCEMENT_DELETE_SUCCESS,
  ANNOUNCEMENT_DETAILS_VIEW_SUCCESS,
  ANNOUNCEMENT_LIST_VIEW_SUCCESS,
  ANNOUNCEMENT_UPDATE_SUCCESS,
} from "./actionType";

export const createAnnouncement = (data) => {
  return {
    type: ANNOUNCEMENT_CREATE_SUCCESS,
    payload: data,
  };
};

export const updateAnnouncement = (data) => {
  return {
    type: ANNOUNCEMENT_UPDATE_SUCCESS,
    payload: data,
  };
};

export const announcementListView = (data) => {
  return {
    type: ANNOUNCEMENT_LIST_VIEW_SUCCESS,
    payload: data,
  };
};

export const announcementViewDetails = (data) => {
  return {
    type: ANNOUNCEMENT_DETAILS_VIEW_SUCCESS,
    payload: data,
  };
};

export const deleteAnnouncement = (data) => {
  return {
    type: ANNOUNCEMENT_DELETE_SUCCESS,
    payload: data,
  };
};
