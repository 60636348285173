import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fetchForgetPasswordReducer from "../../redux/thunk/fetchForgetPasswordReducer";
import fetchPasswordVerifyReducer from "../../redux/thunk/fetchPasswordVerifyReducer";
import Loading from "../ui/Loading";
import Error from "../ui/Error";

export default function ForgetPasswordPage() {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [step, setStep] = useState(1); // 1 for email input, 2 for OTP input
  const [verified, setVerified] = useState(false);
  const forgetPassword = useSelector((state) => state.forgetPassword);
  const { success, isError, error, loading } = forgetPassword || {};
  const passwordVerify = useSelector((state) => state.passwordVerify);
  const {
    success: verifySuccess,
    isError: verifyIsError,
    error: verifyError,
    loading: verifyLoading,
  } = passwordVerify || {};
  const dispatch = useDispatch();

  const handleEmailSubmit = () => {
    dispatch(fetchForgetPasswordReducer(email));
  };

  const handleOtpSubmit = () => {
    dispatch(fetchPasswordVerifyReducer(email, otp));
  };
  useEffect(() => {
    if (success) {
      setStep(2);
    }
    if (verifySuccess) {
      setVerified(true);
    }
  }, [success, verifySuccess]);

  return (
    <div>
      <div className="forget-password-container">
        {(loading || verifyLoading) && (
          <div>
            <Loading />
          </div>
        )}
        <div className="forget-password-box">
          {step === 1 && (
            <>
              <h2>Forget Password</h2>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="forget-password-input"
              />
              <button
                onClick={handleEmailSubmit}
                className="forget-password-button"
              >
                Send
              </button>
            </>
          )}
          {step === 2 && (
            <>
              <h2>Enter OTP</h2>
              <input
                type="text"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="forget-password-input"
              />
              <button
                onClick={handleOtpSubmit}
                className="forget-password-button"
              >
                Verify
              </button>
            </>
          )}
          {verified && (
            <>
              <h2>Verification Successful</h2>
              <p>You will be redirected to the home page shortly...</p>
              {/* Simulate redirection to home page */}
              {setTimeout(() => {
                window.location.href = "/home"; // Redirect to home page
              }, 3000)}
            </>
          )}
          {isError && <Error message={error} />}
          {verifyIsError && <Error message={verifyError} />}
        </div>
      </div>
    </div>
  );
}
