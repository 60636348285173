import { useNavigate } from "react-router-dom";
import deleteIcon from "../assets/delete.png";
import { useDispatch } from "react-redux";
import fetchIconDeleteReducer from "../redux/thunk/fetchIconDeleteReducer";

export default function SingleApp({
  id,
  title,
  image,
  url,
  color,
  isFolder,
  deleteShow,
  refreshOnDelete,
}) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const handleredirect = (e) => {
    e.preventDefault();
    isFolder
      ? navigate(`/folder_app/${id}`)
      : window.open(url, "_blank", "noopener,noreferrer");
    // );
  };

  const deleteHandler = (e) => {
    e.preventDefault();
    dispatch(fetchIconDeleteReducer(id));
    refreshOnDelete(true);
  };
  // to={isFolder ? `/folder_app/${id}` : url}

  return (
    <>
      <div
        style={{
          textDecoration: "none",
          color: "black",
          display: "flex",
          position: "relative",
          cursor: "pointer",
        }}
        onClick={deleteShow ? deleteHandler : handleredirect}
        className="apps-element"
      >
        {isFolder && (
          <span
            style={{
              position: "absolute",
              width: "60px",
              height: "15px",
              background: "#791615",
              top: "6px",
              right: "6px",
              transform: "rotate(46deg)",
              borderTopLeftRadius: "20px",
              borderBottomRightRadius: "20px",
              fontSize: "12px",
              textAlign: "center",
              color: "white",
            }}
          >
            Folder
          </span>
        )}
        {deleteShow && (
          <img
            src={deleteIcon}
            alt="deleteIcon"
            style={{
              width: "20px",
              height: "20px",
              position: "absolute",
            }}
          />
        )}

        <div
          style={{
            width: "70px",
            height: "90px",
            textAlign: "center",
          }}
        >
          <div
            style={{
              width: "45px",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "5px",
              // backgroundColor: "#79130f",
              backgroundColor: isFolder ? "#FCD354" : color,
              margin: "10px auto",
            }}
          >
            <img
              src={image}
              alt={title}
              style={{ width: "30px", height: "30px" }}
            />
          </div>
          <p
            style={{
              fontSize: "12px",
              fontWeight: "bolder",
            }}
          >
            {title}
          </p>
        </div>
      </div>
    </>
  );
}
