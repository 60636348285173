import React, { useState } from "react";
import News from "./News";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function Announce() {
  const announcementList = useSelector((state) => state.announcementList);
  const handleRefresh = (message) => {
    console.log(message);
  };

  const { announcements } = announcementList || {};
  return (
    <div>
      <p style={{ fontWeight: "bold" }}>Announcements</p>

      <div
        style={{
          height: "auto",
          borderRadius: "12px",
          background: "#e4e3e5",
          padding: "10px",
        }}
      >
        {announcements?.length > 0 ? (
          announcements
            ?.slice(-3)
            .reverse()
            .map((announcement, index) => (
              <News
                key={index}
                announcement={announcement}
                onRefresh={handleRefresh}
              />
            ))
        ) : (
          <div style={{ textAlign: "center" }}>No Data Found</div>
        )}
        {announcements?.length > 3 && (
          <div style={{ textAlign: "center", padding: "10px 0px" }}>
            <Link to="/announcement" style={{ textDecoration: "none" }}>
              View All ({announcements?.length})
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
