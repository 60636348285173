import React from "react";
import SingleApp from "./SingleApp";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "./ui/Loading";
import Error from "./ui/Error";

export default function MyApp() {
  const myAppList = useSelector((state) => state.myAppList);
  const { loading, isError, folders, error } = myAppList || {};

  return (
    <div>
      <p style={{ fontWeight: "bold" }}>My Apps</p>

      <div
        style={{
          height: "180px",
          borderRadius: "12px",
          background: "#e4e3e5",
        }}
      >
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "10px",
            }}
          >
            <Loading />
          </div>
        )}
        <div className="app-container">
          {folders?.length > 0 ? (
            folders
              ?.slice(-10)
              ?.map((folder, index) => (
                <SingleApp
                  key={index}
                  id={folder.id}
                  title={folder.title}
                  image={folder.image}
                  color={folder?.color}
                  url={folder.url}
                  isFolder={folder.isFolder}
                />
              ))
          ) : (
            <div
              style={{
                width: "950px",
                textAlign: "center",
              }}
            >
              {isError ? <Error message={error} /> : "No Data Record"}
            </div>
          )}
        </div>
        {folders?.length > 10 && (
          <div style={{ textAlign: "center" }}>
            <Link to="/application" style={{ textDecoration: "none" }}>
              View All ({folders?.length})
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
