import { APP_ASSIGN_FAIL, APP_ASSIGN_REQUEST } from "../app/actionType";
import { assignApp } from "../app/actions";
import axios from "./utils/axios";

const fetchAppAssignReducer = (email, selectedApps) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: APP_ASSIGN_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `api/app-icon/icon_assign/${email}/`,
        { icon_id: selectedApps },
        config
      );
      dispatch(assignApp(data));
    } catch (error) {
      dispatch({
        type: APP_ASSIGN_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };
};
export default fetchAppAssignReducer;
