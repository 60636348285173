import {
  DEPARTMENT_DELETE_FAIL,
  DEPARTMENT_DELETE_REQUEST,
} from "../department/actionType";
import { deleteDepartment } from "../department/actions";
import axios from "./utils/axios";

const fetchDepartmentDeleteReducer = (department_name) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: DEPARTMENT_DELETE_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.delete(
        `api/department/delete/${department_name}/`,
        config
      );
      dispatch(deleteDepartment(data));
    } catch (error) {
      dispatch({
        type: DEPARTMENT_DELETE_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };
};
export default fetchDepartmentDeleteReducer;
