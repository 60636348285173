import { ICON_UNASSIGN_FAIL, ICON_UNASSIGN_REQUEST } from "../app/actionType";
import { unassignIcon } from "../app/actions";
import axios from "./utils/axios";

const fetchUnassignIconReducer = (email, selectedIcon) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ICON_UNASSIGN_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `api/app-icon/remove_icon/${email}/`,
        { icons: selectedIcon },
        config
      );
      dispatch(unassignIcon(data));
    } catch (error) {
      dispatch({
        type: ICON_UNASSIGN_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };
};
export default fetchUnassignIconReducer;
