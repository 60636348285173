import {
  PROFILE_DETAILS_FAIL,
  PROFILE_DETAILS_REQUEST,
} from "../user/actionType";
import { profileDetails } from "../user/actions";
import axios from "./utils/axios";

const fetchProfileDetailsReducer = (email) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: PROFILE_DETAILS_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `api/profile/profile-details/${email}/`,
        config
      );
      dispatch(profileDetails(data));
    } catch (error) {
      dispatch({
        type: PROFILE_DETAILS_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };
};
export default fetchProfileDetailsReducer;
