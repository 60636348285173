import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fetchAppCreateReducer from "../redux/thunk/fetchAppCreateReducer";
import fetchFolderListReducer from "../redux/thunk/fetchFolderListReducer";
import Error from "./ui/Error";
import Loading from "./ui/Loading";

export default function CreateApp({ sendAppCloseAction }) {
  const [iconTitle, setIconTitle] = useState("");
  const [iconUrl, setIconUrl] = useState("");
  const [iconColor, setIconColor] = useState("");
  const [folderId, setFolderId] = useState("");
  const [orderIcon, setOrderIcon] = useState(4);
  const [iconImage, setIconImage] = useState(null);
  const folderList = useSelector((state) => state.folderList);
  const appCreate = useSelector((state) => state.appCreate);
  const { loading, isError, error } = appCreate;
  // const { userInfo } = login || {};
  const { folders } = folderList || {};
  const dispatch = useDispatch();
  const handleClick = () => {
    sendAppCloseAction(false);
  };
  const cancelHandler = () => {
    sendAppCloseAction(false);
  };
  const handleImageChange = (e) => {
    setIconImage(e.target.files[0]);
  };
  let iconOrderList = [1, 2, 3, 4, 5];
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      fetchAppCreateReducer(
        iconTitle,
        iconUrl,
        iconColor,
        folderId,
        iconImage,
        orderIcon
      )
    );
    if (!isError) {
      sendAppCloseAction(false);
    }
  };
  useEffect(() => {
    dispatch(fetchFolderListReducer);
  }, [dispatch]);
  return (
    <div className="popup">
      <div className="popup-content">
        <span className="close" onClick={handleClick}>
          &times;
        </span>
        <h1 style={{ padding: "10px 40px" }}>Create App Section </h1>
        {isError && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Error message={error} />
          </div>
        )}
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading />
          </div>
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <form
            style={{
              width: "60%",
              position: "relative",
            }}
            onSubmit={handleSubmit}
          >
            <div
              style={{
                margin: "30px 0px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label style={{ fontSize: "20px" }}>Folder</label>
              <select
                value={folderId}
                onChange={(e) => setFolderId(e.target.value)}
                style={{
                  padding: "10px 20px",
                  width: "262px",
                  fontSize: "16px",
                  textTransform: "uppercase",
                }}
              >
                <option value={null}>Select Folder</option>
                {folders?.map((folder, index) => (
                  <option
                    key={index}
                    value={folder?.id}
                    style={{ textTransform: "uppercase" }}
                  >
                    {folder?.folder_title}
                  </option>
                ))}
              </select>
            </div>
            <div
              style={{
                margin: "30px 0px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label style={{ fontSize: "20px" }}>Icon Title</label>
              <input
                type="text"
                value={iconTitle}
                onChange={(e) => setIconTitle(e.target.value)}
                style={{ padding: "10px 20px", width: "220px" }}
                required
              />
            </div>
            <div
              style={{
                margin: "30px 0px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label style={{ fontSize: "20px" }}>Icon Order</label>
              <select
                value={orderIcon}
                onChange={(e) => setOrderIcon(e.target.value)}
                style={{
                  padding: "10px 20px",
                  width: "262px",
                  fontSize: "16px",
                  textTransform: "uppercase",
                }}
              >
                <option value={null}>Select Icon Order</option>
                {iconOrderList?.map((iconOrder, index) => (
                  <option key={index} value={iconOrder}>
                    {iconOrder}
                  </option>
                ))}
              </select>
            </div>
            <div
              style={{
                margin: "30px 0px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label style={{ fontSize: "20px" }}>Icon Url</label>
              <input
                type="text"
                value={iconUrl}
                onChange={(e) => setIconUrl(e.target.value)}
                style={{ padding: "10px 20px", width: "220px" }}
                required
              />
            </div>
            <div
              style={{
                margin: "30px 0px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label style={{ fontSize: "20px" }}>Select Icon Color</label>
              <input
                type="color"
                value={iconColor}
                onChange={(e) => setIconColor(e.target.value)}
                style={{ padding: "10px 20px", width: "265px" }}
              />
            </div>
            <div
              style={{
                margin: "30px 0px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label style={{ fontSize: "20px" }}>Icon Image</label>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ fontSize: "14px" }}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                margin: "40px 0px",
              }}
            >
              <button
                type="submit"
                style={{
                  width: "140px",
                  height: "35px",
                  borderRadius: "10px",
                  borderStyle: "none",
                  background: "#486776",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                Save
              </button>
              <button
                style={{
                  width: "140px",
                  height: "35px",
                  borderRadius: "10px",
                  borderStyle: "none",
                  background: "red",
                  color: "white",
                  cursor: "pointer",
                }}
                onClick={cancelHandler}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
