import React, { useState } from "react";
import fetchFolderCreateReducer from "../redux/thunk/fetchFolderCreateReducer";
import { useDispatch } from "react-redux";

export default function CreateFolder({ sendFolderCloseAction }) {
  const [folder_title, setFolder_title] = useState("");
  const [folder_image, setFolder_image] = useState(null);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const handleClick = () => {
    sendFolderCloseAction(false);
  };
  const cancelHandler = () => {
    sendFolderCloseAction(false);
  };
  const handleImageChange = (e) => {
    setFolder_image(e.target.files[0]);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(fetchFolderCreateReducer(folder_title, folder_image));
    sendFolderCloseAction(false);
  };
  return (
    <div className="popup">
      <div className="popup-content">
        <span className="close" onClick={handleClick}>
          &times;
        </span>
        <h1 style={{ padding: "10px 40px" }}>Create Folder Section </h1>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <form
            style={{
              width: "60%",
              position: "relative",
            }}
            onSubmit={handleSubmit}
          >
            <div
              style={{
                margin: "30px 0px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label style={{ fontSize: "20px" }}>Folder Title</label>
              <input
                type="text"
                value={folder_title}
                onChange={(e) => setFolder_title(e.target.value)}
                style={{ padding: "10px 20px", width: "220px" }}
              />
            </div>
            <div
              style={{
                margin: "30px 0px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label style={{ fontSize: "20px" }}>Folder Image</label>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ fontSize: "14px" }}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                margin: "40px 0px",
              }}
            >
              <button
                type="submit"
                style={{
                  width: "140px",
                  height: "35px",
                  borderRadius: "10px",
                  borderStyle: "none",
                  background: "#486776",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                Save
              </button>
              <button
                style={{
                  width: "140px",
                  height: "35px",
                  borderRadius: "10px",
                  borderStyle: "none",
                  background: "red",
                  color: "white",
                  cursor: "pointer",
                }}
                onClick={cancelHandler}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
