import {
  DEPARTMENT_UPDATE_FAIL,
  DEPARTMENT_UPDATE_REQUEST,
} from "../department/actionType";
import { updateDepartment } from "../department/actions";
import axios from "./utils/axios";

const fetchDepartmentUpdateReducer = (id, departmentName) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: DEPARTMENT_UPDATE_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `api/department/update/${id}/`,
        { department_name: departmentName },
        config
      );
      dispatch(updateDepartment(data));
    } catch (error) {
      dispatch({
        type: DEPARTMENT_UPDATE_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };
};
export default fetchDepartmentUpdateReducer;
