import React, { useEffect } from "react";
import Introduction from "../Introduction";
import MyApp from "../MyApp";
import Announce from "../Announce";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import fetchMyAppList from "../../redux/thunk/fetchMyAppList";
import fetchAnnouncementListViewReducer from "../../redux/thunk/fetchAnnouncementViewListReducer";

export default function Home() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);
  const { userInfo } = login || {};
  const { first_name = null, last_name = null } = userInfo || {};
  useEffect(() => {
    dispatch(fetchMyAppList);
    dispatch(fetchAnnouncementListViewReducer);
    if (!userInfo) {
      navigate("/");
    }
  }, [navigate, userInfo, dispatch]);
  return (
    <div style={{ margin: "20px" }}>
      <Introduction name={`${first_name} ${last_name}`} />
      <MyApp />
      <Announce />
    </div>
  );
}
