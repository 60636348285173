import {
  DEPARTMENT_CREATE_FAIL,
  DEPARTMENT_CREATE_REQUEST,
} from "../department/actionType";
import { createDepartment } from "../department/actions";
import axios from "./utils/axios";

const fetchDepartmentCreateReducer = (departmentName) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: DEPARTMENT_CREATE_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `api/department/create/`,
        { department_name: departmentName },
        config
      );
      dispatch(createDepartment(data));
    } catch (error) {
      dispatch({
        type: DEPARTMENT_CREATE_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };
};
export default fetchDepartmentCreateReducer;
