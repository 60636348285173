export const ANNOUNCEMENT_CREATE_REQUEST =
  "announcement/ANNOUNCEMENT_CREATE_REQUEST";
export const ANNOUNCEMENT_CREATE_SUCCESS =
  "announcement/ANNOUNCEMENT_CREATE_SUCCESS";
export const ANNOUNCEMENT_CREATE_FAIL = "announcement/ANNOUNCEMENT_CREATE_FAIL";

export const ANNOUNCEMENT_UPDATE_REQUEST =
  "announcement/ANNOUNCEMENT_UPDATE_REQUEST";
export const ANNOUNCEMENT_UPDATE_SUCCESS =
  "announcement/ANNOUNCEMENT_UPDATE_SUCCESS";
export const ANNOUNCEMENT_UPDATE_FAIL = "announcement/ANNOUNCEMENT_UPDATE_FAIL";

export const ANNOUNCEMENT_DELETE_REQUEST =
  "announcement/ANNOUNCEMENT_DELETE_REQUEST";
export const ANNOUNCEMENT_DELETE_SUCCESS =
  "announcement/ANNOUNCEMENT_DELETE_SUCCESS";
export const ANNOUNCEMENT_DELETE_FAIL = "announcement/ANNOUNCEMENT_DELETE_FAIL";

export const ANNOUNCEMENT_LIST_VIEW_REQUEST =
  "announcement/ANNOUNCEMENT_LIST_VIEW_REQUEST";
export const ANNOUNCEMENT_LIST_VIEW_SUCCESS =
  "announcement/ANNOUNCEMENT_LIST_VIEW_SUCCESS";
export const ANNOUNCEMENT_LIST_VIEW_FAIL =
  "announcement/ANNOUNCEMENT_LIST_VIEW_FAIL";

export const ANNOUNCEMENT_DETAILS_VIEW_REQUEST =
  "announcement/ANNOUNCEMENT_DETAILS_VIEW_REQUEST";
export const ANNOUNCEMENT_DETAILS_VIEW_SUCCESS =
  "announcement/ANNOUNCEMENT_DETAILS_VIEW_SUCCESS";
export const ANNOUNCEMENT_DETAILS_VIEW_FAIL =
  "announcement/ANNOUNCEMENT_DETAILS_VIEW_FAIL";
