import React, { useEffect, useState } from "react";
import SingleApp from "../SingleApp";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import fetchMyAppList from "../../redux/thunk/fetchMyAppList";
import CreateFolder from "../CreateFolder";
import CreateApp from "../CreateApp";
import Loading from "../ui/Loading";
import Error from "../ui/Error";

export default function Application() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [createAppOpen, setcreateAppOpen] = useState(false);
  const [createFolderOpen, setcreateFolderOpen] = useState(false);
  const [formClosed, setFormClosed] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [deleteIconShow, setDeleteIconShow] = useState(false);
  const login = useSelector((state) => state.login);
  const { userInfo } = login || {};
  const myAppList = useSelector((state) => state.myAppList);
  const { loading, isError, folders, error } = myAppList || {};
  console.log(folders);
  const closeDetails = (newMessage) => {
    setcreateAppOpen(newMessage);
    setcreateFolderOpen(newMessage);
    setFormClosed(true);
  };
  const refreshHandle = (msg) => {
    setRefresh(msg);
  };
  const appHandler = () => {
    setcreateAppOpen(true);
  };
  const folderHandler = () => {
    setcreateFolderOpen(true);
  };
  const deleteShowHandler = () => {
    setDeleteIconShow((prevState) => !prevState);
  };

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    }
    dispatch(fetchMyAppList);
    setFormClosed(false);
    setRefresh(false);
  }, [navigate, userInfo, dispatch, formClosed, refresh]);
  console.log(userInfo?.isAdmin);
  return (
    <>
      <div style={{ margin: "20px" }}>
        <h1>Application</h1>

        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0px 30px",
            }}
          >
            <div style={{ padding: "20px" }}>
              <h2>Apps & Folders </h2>
              <div
                style={{
                  color: "red",
                  fontStyle: "italic",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={deleteShowHandler}
              >
                {userInfo && userInfo.isAdmin
                  ? deleteIconShow
                    ? "Cancel Delete"
                    : "Click For Delete"
                  : ""}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "300px",
              }}
            >
              {userInfo?.isAdmin && (
                <div className="folder-create" onClick={folderHandler}>
                  Create Folder
                </div>
              )}
              {userInfo?.isAdmin && (
                <div className="app-create" onClick={appHandler}>
                  Create App
                </div>
              )}
            </div>
          </div>
          <br />
          {loading && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Loading />
            </div>
          )}
          <div className="app-container">
            {folders && folders?.length > 0
              ? folders?.map((folder, index) => (
                  <SingleApp
                    key={index}
                    id={folder.id}
                    title={folder.title}
                    image={folder.image}
                    color={folder.color}
                    url={folder.url}
                    isFolder={folder.isFolder}
                    deleteShow={deleteIconShow}
                    refreshOnDelete={refreshHandle}
                  />
                ))
              : !isError && (
                  <div
                    style={{
                      width: "950px",
                      textAlign: "center",
                    }}
                  >
                    No Data Found
                  </div>
                )}
          </div>
          {isError && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Error message={error} />
            </div>
          )}
        </div>
      </div>
      {createAppOpen && <CreateApp sendAppCloseAction={closeDetails} />}
      {createFolderOpen && (
        <CreateFolder sendFolderCloseAction={closeDetails} />
      )}
    </>
  );
}
