export default function Footer() {
  return (
    <footer
      className="pt-6"
      style={{ textAlign: "center", margin: "60px 0px 10px" }}
    >
      <div>
        <div>Copyright 2024 VALLEY HATCHERY.</div>
      </div>
    </footer>
  );
}
