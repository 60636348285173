import {
  ANNOUNCEMENT_CREATE_FAIL,
  ANNOUNCEMENT_CREATE_REQUEST,
  ANNOUNCEMENT_CREATE_SUCCESS,
  ANNOUNCEMENT_DELETE_FAIL,
  ANNOUNCEMENT_DELETE_REQUEST,
  ANNOUNCEMENT_DELETE_SUCCESS,
  ANNOUNCEMENT_DETAILS_VIEW_FAIL,
  ANNOUNCEMENT_DETAILS_VIEW_REQUEST,
  ANNOUNCEMENT_DETAILS_VIEW_SUCCESS,
  ANNOUNCEMENT_LIST_VIEW_FAIL,
  ANNOUNCEMENT_LIST_VIEW_REQUEST,
  ANNOUNCEMENT_LIST_VIEW_SUCCESS,
  ANNOUNCEMENT_UPDATE_FAIL,
  ANNOUNCEMENT_UPDATE_REQUEST,
  ANNOUNCEMENT_UPDATE_SUCCESS,
} from "./actionType";

export const announcementListReducer = (state = [], action) => {
  switch (action.type) {
    case ANNOUNCEMENT_LIST_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ANNOUNCEMENT_LIST_VIEW_SUCCESS:
      return {
        loading: false,
        announcements: action.payload,
        success: true,
        isError: false,
      };
    case ANNOUNCEMENT_LIST_VIEW_FAIL:
      return {
        loading: false,
        departments: [],
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const createAnnouncementReducer = (state = {}, action) => {
  switch (action.type) {
    case ANNOUNCEMENT_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ANNOUNCEMENT_CREATE_SUCCESS:
      return {
        loading: false,
        announcement: action.payload,
        success: true,
        isError: false,
      };
    case ANNOUNCEMENT_CREATE_FAIL:
      return {
        loading: false,
        announcement: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const updateAnnouncementReducer = (state = {}, action) => {
  switch (action.type) {
    case ANNOUNCEMENT_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ANNOUNCEMENT_UPDATE_SUCCESS:
      return {
        loading: false,
        announcement: action.payload,
        success: true,
        isError: false,
      };
    case ANNOUNCEMENT_UPDATE_FAIL:
      return {
        loading: false,
        announcement: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const announcementViewDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case ANNOUNCEMENT_DETAILS_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ANNOUNCEMENT_DETAILS_VIEW_SUCCESS:
      return {
        loading: false,
        announcement: action.payload,
        success: true,
        isError: false,
      };
    case ANNOUNCEMENT_DETAILS_VIEW_FAIL:
      return {
        loading: false,
        announcement: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const deleteAnnouncementReducer = (state = {}, action) => {
  switch (action.type) {
    case ANNOUNCEMENT_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ANNOUNCEMENT_DELETE_SUCCESS:
      return {
        loading: false,
        announcement: action.payload,
        success: true,
        isError: false,
      };
    case ANNOUNCEMENT_DELETE_FAIL:
      return {
        loading: false,
        announcement: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};
