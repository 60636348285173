import { FOLDER_CREATE_FAIL, FOLDER_CREATE_REQUEST } from "../app/actionType";
import { createFolder } from "../app/actions";
import axios from "./utils/axios";

const fetchFolderCreateReducer = (folder_title, folder_image) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: FOLDER_CREATE_REQUEST });

      const {
        login: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const formData = new FormData();
      formData.append("folder_title", folder_title);
      formData.append("folder_image", folder_image);

      const { data } = await axios.post(
        `api/app-icon/create-folder/`,
        formData,
        config
      );

      dispatch(createFolder(data));
    } catch (error) {
      dispatch({
        type: FOLDER_CREATE_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };
};

export default fetchFolderCreateReducer;
