import { FOLDER_LIST_FAIL, FOLDER_LIST_REQUEST } from "../app/actionType";
import { folderList } from "../app/actions";
import axios from "./utils/axios";

const fetchFolderListReducer = async (dispatch, getState) => {
  try {
    dispatch({ type: FOLDER_LIST_REQUEST });
    const {
      login: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`api/app-icon/get-all-folder/`, config);
    dispatch(folderList(data));
  } catch (error) {
    dispatch({
      type: FOLDER_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
export default fetchFolderListReducer;
