import { Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Login from "./components/pages/Login";
import Home from "./components/pages/Home";
import Notification from "./components/pages/Notification";
import Application from "./components/pages/Application";
import FolderApp from "./components/pages/FolderApp";
import Profile from "./components/pages/Profile";
import ForgetPasswordPage from "./components/pages/ForgetPassword";
import Users from "./components/pages/Users";
import ProfileDetails from "./components/pages/ProfileDetails";
import Departments from "./components/pages/Departments";
import NotFound from "./components/ui/NotFound";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/forget-password" element={<ForgetPasswordPage />} />
        <Route path="/home" element={<Home />} />
        <Route path="/users" element={<Users />} />
        <Route path="/users/:email" element={<ProfileDetails />} />
        <Route path="/announcement" element={<Notification />} />
        <Route path="/application" element={<Application />} />
        <Route path="/departments" element={<Departments />} />
        <Route path="/folder_app/:folderId" element={<FolderApp />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
