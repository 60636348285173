import {
  ANNOUNCEMENT_LIST_VIEW_FAIL,
  ANNOUNCEMENT_LIST_VIEW_REQUEST,
} from "../announcement/actionType";
import { announcementListView } from "../announcement/actions";
import axios from "./utils/axios";

const fetchAnnouncementListViewReducer = async (dispatch, getState) => {
  try {
    dispatch({ type: ANNOUNCEMENT_LIST_VIEW_REQUEST });
    const {
      login: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`api/announcement/get_all/`, config);
    dispatch(announcementListView(data));
  } catch (error) {
    dispatch({
      type: ANNOUNCEMENT_LIST_VIEW_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
export default fetchAnnouncementListViewReducer;
