import React from "react";

export default function SelectedApp({
  id,
  title,
  image,
  url,
  color,
  isFolder,
  selected,
  handleSelectChange,
}) {
  const handleSelect = (e) => {
    e.stopPropagation(); // Prevents the onClick event from triggering
    handleSelectChange(id);
  };

  return (
    <div
      style={{
        textDecoration: "none",
        height: "80px",
        width: "95px",
        color: "black",
        display: "flex",
        position: "relative",
        cursor: "pointer",
        marginTop: "20px",
      }}
      onClick={() => handleSelectChange(id)} // Fix onClick event
      className="apps-element"
    >
      <input
        type="checkbox"
        checked={selected}
        onChange={handleSelect} // Fix onChange event
        style={{ marginRight: "10px" }}
      />
      {isFolder && (
        <span
          style={{
            position: "absolute",
            width: "60px",
            height: "15px",
            background: "#791615",
            top: "6px",
            left: "50px",
            transform: "rotate(46deg)",
            borderTopLeftRadius: "20px",
            borderBottomRightRadius: "20px",
            fontSize: "12px",
            textAlign: "center",
            color: "white",
          }}
        >
          Folder
        </span>
      )}
      <div
        style={{
          width: "70px",
          height: "90px",
          textAlign: "center",
        }}
      >
        <div
          style={{
            width: "45px",
            height: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "5px",
            backgroundColor: isFolder ? "#FCD354" : color,
            margin: "10px auto",
          }}
        >
          <img
            src={image}
            alt={title}
            style={{ width: "30px", height: "30px" }}
          />
        </div>
        <p
          style={{
            fontSize: "12px",
            fontWeight: "bolder",
          }}
        >
          {title}
        </p>
      </div>
    </div>
  );
}
