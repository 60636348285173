import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SingleApp from "../SingleApp";
import folder_icon from "../../assets/folders.png";
import { useDispatch, useSelector } from "react-redux";
import fetchAppFolderDetailsReducer from "../../redux/thunk/fetchAppsFolderDetails";
import fetchFolderDetailsReducer from "../../redux/thunk/fetchFolderDetails";
import Loading from "../ui/Loading";
import Error from "../ui/Error";

export default function FolderApp() {
  const { folderId } = useParams();
  let navigate = useNavigate();
  const login = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const appFolderDetails = useSelector((state) => state.appFolderDetails);
  const folderDetails = useSelector((state) => state.folderDetails);
  const { loading, isError, apps = [], error } = appFolderDetails || {};
  const { folder } = folderDetails || {};
  const appsArray = Array.isArray(apps) ? apps : [];
  const { folder_image = folder_icon, folder_title = null } = folder || {};
  const { userInfo } = login || {};

  const [refresh, setRefresh] = useState(false);
  const [deleteIconShow, setDeleteIconShow] = useState(false);
  const refreshHandle = (msg) => {
    setRefresh(msg);
  };
  const deleteShowHandler = () => {
    setDeleteIconShow((prevState) => !prevState);
  };
  useEffect(() => {
    dispatch(fetchAppFolderDetailsReducer(folderId));
    dispatch(fetchFolderDetailsReducer(folderId));
    if (!userInfo) {
      navigate("/");
    }
    setRefresh(false);
  }, [navigate, userInfo, dispatch, folderId, refresh]);
  return (
    <div style={{ margin: "20px" }}>
      <div style={{ display: "flex" }}>
        <div
          style={{
            width: "60px",
            height: "60px",
            borderRadius: "50%",
            border: "2px solid #007bff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={folder_image}
            alt="folder_img"
            style={{ width: "50px", height: "50px" }}
          />
        </div>
        <h3 style={{ marginLeft: "30px" }}>Folder Name : {folder_title}</h3>
      </div>
      <div>
        <h2 style={{ padding: "10px 20px" }}>Apps </h2>
        <div
          style={{
            color: "red",
            fontStyle: "italic",
            fontSize: "14px",
            padding: "5px 20px",
            cursor: "pointer",
          }}
          onClick={deleteShowHandler}
        >
          {userInfo && userInfo.isAdmin
            ? deleteIconShow
              ? "Cancel Delete"
              : "Click For Delete"
            : ""}
        </div>
        <br />
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading />
          </div>
        )}
        {isError && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Error message={error} />
          </div>
        )}
        <div className="app-container">
          {appsArray?.map((app, index) => (
            <SingleApp
              key={index}
              id={app._id}
              title={app.icon_title}
              image={app.icon_image}
              url={app.icon_url}
              color={app.color}
              isFolder={false}
              deleteShow={deleteIconShow}
              refreshOnDelete={refreshHandle}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
