import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import fetchUserList from "../../redux/thunk/fetchUserListReducer";
import CreateUser from "../CreateUser";
import User from "../User";

export default function Users() {
  const [addUserOpen, setAddUserOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);
  const userList = useSelector((state) => state.userList);
  const { users } = userList || {};
  const { loading, isError, userInfo, error } = login || {};
  const closeDetails = (newMessage) => {
    setAddUserOpen(newMessage);
  };
  const addUserHandler = () => {
    setAddUserOpen(true);
  };
  const handleRefresh = (message) => {
    setRefresh(message);
  };

  useEffect(() => {
    dispatch(fetchUserList);
    if (!userInfo) {
      navigate("/");
    }
    setRefresh(false);
  }, [navigate, userInfo, dispatch, refresh]);
  return (
    <>
      <div style={{ padding: "10px 30px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0px 30px",
          }}
        >
          <h2 style={{ padding: "20px" }}>Users List</h2>
          <div className="user-create" onClick={addUserHandler}>
            Create User
          </div>
        </div>
        <br />
        <br />
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr style={{ textAlign: "center" }}>
              <th style={{ border: "1px solid #ddd", padding: "8px" }}>ID</th>
              <th style={{ border: "1px solid #ddd", padding: "8px" }}>#</th>
              <th style={{ border: "1px solid #ddd", padding: "8px" }}>Name</th>
              <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                Email
              </th>
              <th style={{ border: "1px solid #ddd", padding: "8px" }}>Dept</th>
              <th style={{ border: "1px solid #ddd", padding: "8px" }}>#</th>
              <th style={{ border: "1px solid #ddd", padding: "8px" }}>View</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(users) &&
              users?.map((user, index) => (
                <User
                  user={user}
                  index={index}
                  key={index}
                  onRefresh={handleRefresh}
                />
              ))}
          </tbody>
        </table>
      </div>
      {addUserOpen && (
        <CreateUser
          sendAppCloseAction={closeDetails}
          createOnRefresh={handleRefresh}
        />
      )}
    </>
  );
}
